import React from "react";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";
import { useTranslation } from "react-i18next";

function ProductVideo({ links }) {
  const { t } = useTranslation("common");
  if (!links?.length > 0) return null;
  return (
    <div className="mt-3">
      <h2 className="f30 quickSand text-blue bold ps-3">
        {t("Product video")}
      </h2>

      <div className="row">
        {links?.map((videoLink) => {
          const link = videoLink ? `${IMAGE_END_POINT}${videoLink}` : undefined;
          return (
            <video
              controls
              className={`h-auto mt-4 ${links?.length === 1 ? "col-12" : links?.length === 2 ? "col-6" : links?.length >= 3 ? "col-4" : ""}`}
            >
              <source src={link} />
            </video>
          );
        })}
      </div>
    </div>
  );
}

export default ProductVideo;
