import _ from "lodash";
import API from "../Api/Api";
import CalendarInput from "../Components/Common/FormElements/CalendarInput/CalendarInput";
import Select from "../Components/Common/FormElements/Select/Select";
import TextInput from "../Components/Common/FormElements/TextInput/TextInput";
import { API_END_POINT } from "../Api/EndPoints";

export const getStatusStyle = (status, t) => {
  const statusStyle = { statusBgStyle: "", statusTextStyle: "" };
  switch (status) {
    case t("in progress", { ns: "common" }):
      return {
        ...statusStyle,
        statusBgStyle: "bg-lightOrange",
        statusTextStyle: "text-orange",
      };
    case t("Pending", { ns: "common" }):
    case t("Out of stock", { ns: "common" }):
      return {
        ...statusStyle,
        statusBgStyle: "bg-redCalendar",
        statusTextStyle: "text-brightRed",
      };
    case t("Paid", { ns: "common" }):
    case t("Resolved", { ns: "common" }):
      return {
        ...statusStyle,
        statusBgStyle: "bg-green",
        statusTextStyle: "text-green",
      };

    case t("Deposit", { ns: "common" }):
    case t("Canceled", { ns: "common" }):
      return {
        ...statusStyle,
        statusBgStyle: "bg-grey",
        statusTextStyle: "text-brightGrey",
      };

    case t("On sale", { ns: "common" }):
      return {
        ...statusStyle,
        statusBgStyle: "bg-lightPink",
        statusTextStyle: "text-pink",
      };
    case t("In stock", { ns: "common" }):
    case t("Delivered", { ns: "common" }):
      return {
        ...statusStyle,
        statusBgStyle: "bg-green",
        statusTextStyle: "text-green",
      };

    case t("Paid/pending delivery", { ns: "common" }):
      return {
        ...statusStyle,
        statusBgStyle: "bg-snowGreen",
        statusTextStyle: "text-green",
      };

    default:
      return {
        ...statusStyle,
        statusBgStyle: "",
        statusTextStyle: "",
      };
  }
};

export const getCalendarEventStyle = (eventType) => {
  const eventStyle = { eventBgStyle: "", textBarStyle: "" };
  switch (eventType) {
    case "Événement":
      return {
        ...eventStyle,
        eventBgStyle: "bg-softPink",
        textBarStyle: "wildStrawberry",
      };
    case "Livraisons":
      return {
        ...eventStyle,
        eventBgStyle: "bg-iceberg",
        textBarStyle: "aquaGreen",
      };
    case "Commande":
      return {
        ...eventStyle,
        eventBgStyle: "bg-treebrown",
        textBarStyle: "oakBrown",
      };
    case "Livraison SAV estimée":
      return {
        ...eventStyle,
        eventBgStyle: "bg-papaya",
        textBarStyle: "yellowOrange",
      };

    case "Livraison produit estimée":
      return {
        ...eventStyle,
        eventBgStyle: "bg-snowGreen",
        textBarStyle: "yellowGreen",
      };

    case "Salon":
      return {
        ...eventStyle,
        eventBgStyle: "bg-pink",
        textBarStyle: "jasminePurple",
      };

    case "Prospects reçus":
      return {
        ...eventStyle,
        eventBgStyle: "bg-blueSky",
        textBarStyle: "blueSky",
      };

    default:
      return {
        ...eventStyle,
        eventBgStyle: "",
        textBarStyle: "",
      };
  }
};

export const getFormEltType = (
  label,
  type,
  surfixText,
  placeholder,
  valueCustomStyle,
  icon,
  currencyChoice,
  name,
  handleChange,
  inputType,
  withMinDate,
  currencySelectName,
  disabled,
  value,
  currencyDefaultValue,
  options
) => {
  const CuveIcon = (
    <div
      style={{
        width: "12px",
        height: "12px",
        background: placeholder === "beige" ? "#BFC2BC" : "#775344",
      }}
      className={`br50`}
    ></div>
  );
  switch (type) {
    case "select":
      return (
        <Select
          options={[
            {
              name: icon ? CuveIcon : label,
              code: icon ? CuveIcon : label,
            },
            {
              name: icon ? CuveIcon : "Option 1",
              code: icon ? CuveIcon : "Option 1",
            },
          ]}
          defaultValue={{
            name: icon ? CuveIcon : label,
            code: icon ? CuveIcon : label,
          }}
          placeholder={icon ? CuveIcon : placeholder}
          valueCustomStyle={valueCustomStyle && "12px"}
          name={name}
        />
      );
    case "selectEntrepot":
      return (
        <Select
          options={options}
          defaultValue={{
            name: value,
            code: value,
          }}
          placeholder={value ? value : placeholder}
          valueCustomStyle={valueCustomStyle && "12px"}
          name={name}
          value={value}
          callback={handleChange ? (e) => handleChange(e, name) : null}
        />
      );

    case "calendarInput":
      return (
        <CalendarInput
          className="w100"
          placeholder={placeholder}
          callback={handleChange ? (e) => handleChange(e, name) : null}
          name={name}
          withMinDate={withMinDate}
          value={value}
        />
      );
    case "input":
      return (
        <TextInput
          className={"w100"}
          placeholder={placeholder ? placeholder : label}
          surfixText={surfixText}
          inputClassName={valueCustomStyle ?? undefined}
          placeholderStyle={valueCustomStyle ?? undefined}
          customSurfixSize={valueCustomStyle ? "f12" : undefined}
          currencyChoice={currencyChoice}
          currencySelectName={currencySelectName}
          value={
            ["returnCost"]?.includes(name) ? formatFloat(value, "-") : value
          }
          callback={handleChange ? (e) => handleChange(e) : null}
          type={inputType ? inputType : null}
          currencyOnChange={handleChange ? (e) => handleChange(e) : null}
          currencyDefaultValue={currencyDefaultValue}
          name={name}
          disabled={disabled}
          onWheel={(e) => e.target.blur()}
        />
      );
    case "inputInfo":
      return (
        <TextInput
          className="bg-softGrey br5"
          placeholder="Date de livraison + 2 ans"
          disabled
          name={name}
        />
      );
    default:
      return null;
  }
};

export const getErrorMessage = (message) => {
  switch (message) {
    case "The provided current password is invalid":
      return "Le mot de passe actuel fourni n'est pas valide !";
    case "password must be at least 6 characters":
      return "Le nouveau mot de passe doit contenir au moins 6 caractères !";
    default:
      return message;
  }
};

export const getRandomNumber = (min = 1000, max = 9999) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const selectValue = (option) => option?.code;

export const uploadFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append("files", file);
    const response = await API.post("/api/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data[0].id;
  } catch (error) {
    throw new Error("UPLOAD-FAILED", error);
  }
};

export const convertedByType = (value) =>
  isNaN(value) ? value : Number(value);

export const isDefined = (v) => v !== null && v !== undefined;

export const handleValue = (value) => {
  const val =
    typeof value === "string" && value?.includes("#")
      ? value?.replace("#", "")
      : value;
  return val && val !== "undefined" && val !== "Invalid date" ? val : "-";
};

export const getProductType = (productType, t) => {
  switch (productType) {
    case 2:
      return t("Accessories");
    case 3:
      return t("Marketing Products");
    case 4:
      return t("Spare Part");
    case 5:
      return t("Services");
    case 6:
      return t("Other branded products");

    default:
      break;
  }
};

export const formatFloat = (value, placeholder = "") => {
  if (!isDefined(value) || isNaN(value)) return placeholder;
  const floatValue = parseFloat(value);
  if (floatValue - Math.floor(floatValue) === 0) {
    return value;
  }
  return floatValue?.toFixed(2);
};

export const groupByQuantity = (products) => {
  const spaProducts = products?.filter(({ productType }) => productType === 1);
  const otherProducts = products?.filter(
    ({ productType }) => productType !== 1
  );

  let grouped = _.groupBy(
    otherProducts,
    (product) =>
      product?.productName +
      (product?.serialNumber === null ? "" : product?.serialNumber)
  );
  let result = _.map(grouped, (group) => {
    return {
      ...group[0],
      productName: group[0]?.productName,
      // serialNumber: group[0]?.serialNumber,
      quantity: _.sumBy(group, "quantity"),
    };
  });

  return [...spaProducts, ...result];
};

export const createFileFromUrl = async (fileUrl, fileName) => {
  // Fetch the file from the URL
  const response = await fetch(fileUrl);

  // Convert the response to a Blob
  const blob = await response.blob();

  // Create a File object from the Blob
  const file = new File([blob], fileName, { type: blob.type });

  return file;
};

export const getEntrepotSelectValue = (entrepots, t) => {
  if (!entrepots) {
    return [];
  }
  return [
    { name: t("Select"), code: t("Select") },
    ...entrepots?.map((entrepot) => {
      return {
        name: entrepot?.attributes?.name,
        code: entrepot?.attributes?.name,
        id: entrepot?.id,
      };
    }),
  ];
};

// export const checkIsProduct = (productId) => async () => {
//   try {
//     const { data } = await API.get(`${API_END_POINT}api/products/${productId}`);
//     console.log({ dataaaaa: data });
//     const isProduct = data?.data?.attributes?.productType === 1;
//     return isProduct;
//   } catch (error) {
//     console.log({ error });
//   }
// };
