import React from "react";
import CommandesViewContent from "./CommandesViewContent";
import ViewScroller from "../Common/ViewScroller/ViewScroller";
import RightModal from "../Modals/RightModal/RightModal";

const CommandesTabContent = ({ activeTab }) => {
  return (
    <ViewScroller>
      {/* <CommandesViewHeader setsearchCommande={setsearchCommande} /> */}

      {/* <CommandesApproViewHeader
        activeTab={activeTab}
        setOrderNumberSearch={setOrderNumberSearch}
      /> */}

      <CommandesViewContent activeTab={activeTab} />
      <RightModal width={80} className="pb-4" />
    </ViewScroller>
  );
};

export default CommandesTabContent;
