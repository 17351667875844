import React, { useEffect } from "react";
import MemberItem from "./MemberItem";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../../REDUX/actions/user";
import LoaderWrapper from "../../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";
import USERPHOTO from "../../../Images/Images/avatar.png";
import { useTranslation } from "react-i18next";
function MembersList() {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { allUsers, userLoading } = useSelector((state) => state.userReducer);
  const { user } = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const filteredUsers = allUsers.filter((client) => {
    return client.username != user?.attributes?.username;
  });

  return (
    <div className="flex-1 d-flex flex-column">
      <div className="listHeader d-flex align-items-center bg-dimGrey p-3">
        <p className="f14 sBold text-brightGrey mb-0 w20 ps-4">
          {t("Last name/First name")}
        </p>
        <p className="f14 sBold text-brightGrey mb-0 w20">
          {t("Phone number")}
        </p>
        <p className="f14 sBold text-brightGrey mb-0 w30">{t("E-mail")}</p>
        <p className="f14 sBold text-brightGrey mb-0 w20">{t("Position")}</p>
      </div>
      <div className="flex-1">
        <LoaderWrapper loading={userLoading}>
          {filteredUsers.map(
            ({
              id,
              lastName,
              firstName,
              phoneNumber,
              email,
              occupation,
              photo,
              userRole,
            }) => (
              <MemberItem
                key={id}
                id={id}
                name={`${lastName} ${firstName} `}
                phone={phoneNumber}
                email={email}
                post={occupation}
                photo={
                  photo?.url ? `${IMAGE_END_POINT}${photo?.url}` : USERPHOTO
                }
                userRole={userRole}
              />
            )
          )}
        </LoaderWrapper>
      </div>
    </div>
  );
}

export default MembersList;
