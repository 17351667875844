import { isDefined } from "../../../Utils";

const getDetailsTableData = (attributes, activeTab, t) => {
  const caracteristique = attributes?.caracteristique?.data?.attributes;
  const extraFields = caracteristique?.extraFields;

  const extraFieldsDetails = extraFields?.map((extraField) => {
    return {
      name: `${extraField?.inputName
        ?.charAt(0)
        ?.toUpperCase()}${extraField?.inputName?.slice(1)}`,
      value: extraField?.valueName ?? "-",
    };
  });
  // const caracteristique = attributes?.caracteristique?.data?.attributes;
  const lightSensor = attributes?.light_sensor_serie?.data?.attributes;
  const equipement = attributes?.equipement?.data?.attributes;
  // console.log(attributes);
  // console.log(attributes);
  // function Helpers
  const getBoolFr = (bool) => {
    return bool && bool !== null ? t("Yes") : t("No");
  };
  if (activeTab === "Features") {
    return [
      // {
      //   name: "En stock:",
      //   value: attributes?.availableQuantity
      //     ? "Livraison Immédiate"
      //     : attributes?.replenishmentDate
      //     ? `en réapro le ${attributes?.replenishmentDate}`
      //     : "hors stock",
      // },
      {
        name: t("Capacity"),
        value: caracteristique?.capacity
          ? `${caracteristique?.capacity} ${t("persons")}`
          : "-",
        hide: !isDefined(caracteristique?.capacity),
      },
      {
        name: t("Dimensions"),
        value: caracteristique?.productDimensions ?? "-",
        hide: !isDefined(caracteristique?.productDimensions),
      },
      {
        name: t("Net weight"),
        value: isDefined(caracteristique?.weight)
          ? `${caracteristique?.weight} kg`
          : "-",
        hide: !isDefined(caracteristique?.weight),
      },
      {
        name: t("Water volume"),
        value: isDefined(caracteristique?.waterVolume)
          ? `${caracteristique?.waterVolume} L`
          : "-",
        hide: !isDefined(caracteristique?.waterVolume),
      },
      {
        name: t("Hydrotherapy jets"),
        value: caracteristique?.hydrotherapyJet ?? "-",
        hide: !isDefined(caracteristique?.hydrotherapyJet),
      },
      {
        name: t("Water pump"),
        value: caracteristique?.waterPump ?? "-",
        hide: !isDefined(caracteristique?.waterPump),
      },
      {
        name: t("Filtration pump"),
        value: caracteristique?.filtrationPump
          ? `${caracteristique?.filtrationPump} HP`
          : "-",
        hide: !isDefined(caracteristique?.filtrationPump),
      },
      {
        name: t("Onboard computer"),
        value: caracteristique?.dashboardComputer ?? "-",
        hide: !isDefined(caracteristique?.dashboardComputer),
      },
      {
        name: t("Balboa electrical box"),
        value: caracteristique?.balboaElectricBox ?? "-",
        hide: !isDefined(caracteristique?.balboaElectricBox),
      },
      {
        name: t("Balboa heating system"),
        value: caracteristique?.balboaHeater
          ? `${caracteristique?.balboaHeater} kw`
          : "-",
        hide: !isDefined(caracteristique?.balboaHeater),
      },
      {
        name: t("Power supply"),
        value: caracteristique?.electricalPowerSource ?? "-",
        hide: !isDefined(caracteristique?.electricalPowerSource),
      },
      ...(extraFieldsDetails ?? []),
    ];
  } else if (activeTab === "Equipments") {
    return [
      {
        name: t("ARISTECH Acrylics"),
        value: getBoolFr(equipement?.acrylicsARISTECH),
      },
      {
        name: t("BioLok system"),
        value: getBoolFr(equipement?.systemeBiolok),
      },
      {
        name: t("Disinfection"),
        value: equipement?.desinfection ?? "-",
      },
      {
        name: t("Cartridge filtration"),
        value: getBoolFr(equipement?.filtrationCartouche),
      },
      {
        name: t("Insulation"),
        value: equipement?.isolation ?? "-",
      },
      {
        name: t("Winter cover"),
        value: getBoolFr(equipement?.winterCover),
      },
      {
        name: t("Speaker"),
        value: getBoolFr(equipement?.hautParleur),
      },
      {
        name: t("Power regulator"),
        value: equipement?.powerController ?? "-",
      },
      {
        name: t("Low consumption filtration"),
        value: getBoolFr(equipement?.filtrationBasseConsomation),
      },
      {
        name: t("Waterfall"),
        value: getBoolFr(equipement?.cascade),
      },
      {
        name: t("Easy Drain"),
        value: getBoolFr(equipement?.easyDrain),
      },
      {
        name: t("ABS base"),
        value: getBoolFr(equipement?.baseABS),
      },
    ];
  } else if (activeTab === "LightSensor Serie") {
    return [
      {
        name: t("Chromotherapy spot"),
        value: lightSensor?.sportChromotherapie ?? "-",
      },
      {
        name: t("Waterline LEDs"),
        value: lightSensor?.LEDsLigneEau ?? "-",
        show: isDefined(lightSensor?.LEDsLigneEau),
      },
      {
        name: t("Backlit jets"),
        value: getBoolFr(lightSensor?.jetsRetroEclaires),
      },
      {
        name: t("Backlit waterfall"),
        value: lightSensor?.backlitWaterfall ?? "No",
      },
      {
        name: t("Backlit river"),
        value: lightSensor?.backlitRiver ?? "No",
      },
      {
        name: t("Backlit headrest"),
        value: lightSensor?.backlitNeckSupport ?? "No",
      },
      {
        name: t("Backlit corners"),
        value: lightSensor?.backlitNeckSupport ?? "No",
      },
      {
        name: t("Backlit panels"),
        value: getBoolFr(lightSensor?.backlitPanels),
      },

      {
        name: t("Backlit skimmer"),
        value: getBoolFr(lightSensor?.backlitSkimmer),
      },
      {
        name: t("Backlit ON/OFF switches"),
        value: getBoolFr(lightSensor?.backlitToggleSwitch),
      },
      // {
      //   name: "Régulateurs rétroéclairés :",
      //   value: ,
      // },
    ];
  }
};

export default getDetailsTableData;
