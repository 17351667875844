// import gql from "graphql-tag";
import { gql } from "@apollo/client";

export const GET_ME = gql`
  query ($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          email
          username
          firstName
          lastName
          phoneNumber
          occupation
          birthdate
          userRole
          profil {
            data {
              id
              attributes {
                reseller {
                  data {
                    id
                    attributes {
                      name
                      commandes {
                        data {
                          id
                          attributes {
                            oldId
                            realDeliveryDate

                            packings {
                              data {
                                id
                                attributes {
                                  serialNumber
                                  refClient
                                  model {
                                    data {
                                      id
                                      attributes {
                                        name
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          panier {
            data {
              id
            }
          }
          photo {
            data {
              attributes {
                url
              }
            }
          }
          business_affiliate {
            data {
              id
              attributes {
                organizations {
                  data {
                    id
                    attributes {
                      commandes {
                        data {
                          id
                        }
                      }
                      delivery_addresses {
                        data {
                          attributes {
                            delivery {
                              data {
                                attributes {
                                  deliveryAddress
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          marque {
            data {
              id
              attributes {
                name
                bgColor
                logo {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
