import API from "../../Api/Api";
import qs from "qs";
import { API_END_POINT } from "../../Api/EndPoints";
import { deleteRecord } from "../../Utils/api";
import { getRandomNumber, isDefined } from "../../Utils";

export const getProfiles = (resellerId) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_PROFILES_LOADING" });
    const query = qs.stringify({
      populate: ["user", "reseller", "lastOrder"],
      filters: {
        reseller: { id: { $eq: resellerId } },
      },
    });
    const {
      data: { data: profiles },
    } = await API.get(`${API_END_POINT}api/profils?${query}`);

    dispatch({ type: "FETCH_PROFILES_SUCCESS", payload: profiles });
  } catch (error) {
    dispatch({ type: "FETCH_PROFILES_ERROR", payload: error });
  }
};

export const getProfile = (id) => async (dispatch) => {
  try {
    if (!id) return;
    dispatch({ type: "FETCH_PROFILE_LOADING" });
    const query = qs.stringify({
      populate: ["user", "reseller", "lastOrder"],
    });
    const {
      data: { data: profileData },
    } = await API.get(`${API_END_POINT}api/profils/${id}?${query}`);

    dispatch({ type: "FETCH_PROFILE_SUCCESS", payload: profileData });
  } catch (error) {
    dispatch({ type: "FETCH_PROFILE_ERROR", payload: error });
  }
};

export const createUpdateProfile =
  (formData, resellerId, userId, profileId, callback) => async (dispatch) => {
    try {
      dispatch({ type: "CREATE_PROFILE_LOADING" });

      const {
        firstName,
        lastName,
        phoneNumber,
        email,
        password,
        occupation,
        marque,
      } = formData;

      const extraUserFields = userId
        ? {}
        : {
            username: `${firstName}-${lastName}-${getRandomNumber(1000, 9999)}`,
            role: 0,
          };
      let data = {};
      if (isDefined(userId)) {
        // UPDATE CASE
        if (isDefined(password))
          data = {
            firstName,
            lastName,
            phoneNumber,
            email,
            password,
            occupation: occupation?.code,
            userRole: 0,
            marque,
            ...extraUserFields,
          };
        else {
          data = {
            firstName,
            lastName,
            phoneNumber,
            email,
            occupation: occupation?.code,
            userRole: 0,
            marque,
            ...extraUserFields,
          };
        }
      } else {
        // CREATE CASE
        data = {
          firstName,
          lastName,
          phoneNumber,
          email,
          password,
          occupation: occupation?.code,
          userRole: 0,
          marque,
          ...extraUserFields,
        };
      }
      const { data: userData } = await API[isDefined(userId) ? "put" : "post"](
        `${API_END_POINT}api/users/${userId ?? ""}`,
        data,
      );

      const query = qs.stringify({
        populate: ["user", "reseller", "lastOrder"],
      });

      const {
        data: { data: profileData },
      } = await API[isDefined(profileId) ? "put" : "post"](
        `${API_END_POINT}api/profils/${profileId ?? ""}?${query}`,
        {
          data: {
            user: userData?.id,
            reseller: resellerId,
          },
        },
      );

      dispatch({ type: "CREATE_PROFILE_SUCCESS", payload: profileData });
      callback();
    } catch (error) {
      dispatch({ type: "CREATE_PROFILE_ERROR", payload: "" });
    }
  };

export const deleteProfile = (id, userId) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_PROFILE_LOADING" });

    await deleteRecord(userId, "users");
    await deleteRecord(id, "profils");

    dispatch({ type: "DELETE_PROFILE_SUCCESS", payload: id });
  } catch (error) {
    dispatch({ type: "DELETE_PROFILE_ERROR", payload: "" });
  }
};

export const clearCurrentProfile = () => async (dispatch) => {
  dispatch({ type: "CLEAR_CURRENT_PROFILE" });
};
