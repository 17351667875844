import moment from "moment";

export const ticketSAVurlFilters = (searchInput, filtersInput) => {
  const searchInputFilter = searchInput
    ? `filters[$and][0][refClient][$containsi]=${searchInput}&`
    : "";
  const statusFilter = filtersInput.status
    ? `filters[$and][1][status][$eq]=${filtersInput.status}&`
    : "";
  const problemFilter = filtersInput.problem
    ? `filters[$and][2][problem][$eq]=${filtersInput.problem}&`
    : "";
  const refClientFilter = filtersInput.refClient
    ? `filters[$and][3][refClient][$containsi]=${filtersInput.refClient}&`
    : "";
  const createdAtFilter = filtersInput.createdAt
    ? `filters[$and][4][createdAt][$eq]=${filtersInput.createdAt.code}&`
    : "";
  const relaunchFilter = filtersInput.relaunch
    ? `filters[$and][5][relaunch][$eq]=${filtersInput.relaunch.code}&`
    : "";
  const revendeurFilter = filtersInput.revendeur
    ? `filters[$and][6][users_permissions_users][id][$eq]=${filtersInput.revendeur.code}&`
    : "";
  const responsableFilter = filtersInput.responsable
    ? `filters[$and][7][users_permissions_users][id][$eq]=${filtersInput.responsable.code}&`
    : "";
  const interventionFilter = filtersInput.intervention
    ? `filters[$and][8][intervention][id][$gte]=1&`
    : "";
  const sinceDateFilter = filtersInput.sinceDate
    ? `filters[$and][9][createdAt][$gte]=${moment(
        filtersInput.sinceDate
      ).format("YYYY-MM-DD")}&`
    : "";
  const untilDateFilter = filtersInput.untilDate
    ? `filters[$and][10][createdAt][$lte]=${moment(
        filtersInput.untilDate
      ).format("YYYY-MM-DD")}&`
    : "";

  return `${searchInputFilter}${statusFilter}${problemFilter}${refClientFilter}${createdAtFilter}${relaunchFilter}${revendeurFilter}${responsableFilter}${interventionFilter}${sinceDateFilter}${untilDateFilter}`;
};
