const initialState = {
  products: [],
  productsLoading: false,
  productsError: null,
  productDetails: [],
  productDetailsLoading: false,
  productDetailsError: null,
  currentProductLoading: false,
  currentProduct: null,
  currentChars: null,
  currentEquipement: null,
  currentLightSensor: null,
  currentAttachements: [],
  currentDocuments: [],
  productsListLoading: false,
  productsListError: null,
  productsList: [],
  deleteLoading: false,
  page: 1,
  productsCount: null,
  relatedProducts: [],
  accessories: [],
  accessoriesLoading: false,
};

const productsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "PRODUCTS_LOADING":
      return {
        ...state,
        productsLoading: true,
      };
    case "RESET_PRODUCTS": {
      return {
        ...state,
        products: [],
        productsCount: null,
        productsLoading: false,
      };
    }
    case "PRODUCTS_COUNT": {
      return {
        ...state,
        productsCount: payload,
      };
    }
    case "PRODUCTS_FETCHED":
      return {
        ...state,
        productsLoading: false,
        products: payload,
      };
    case "PRODUCTS_ERROR":
      return {
        ...state,
        productsLoading: false,
        productsError: payload,
      };
    case "PRODUCT_DETAILS_LOADING":
      return {
        ...state,
        productDetailsLoading: true,
      };
    case "PRODUCT_DETAILS_FETCHED":
      return {
        ...state,
        productDetails: payload,
        productDetailsLoading: false,
      };
    case "PRODUCT_DETAILS_ERROR":
      return {
        ...state,
        productDetailsError: payload,
        productDetailsLoading: false,
      };

    case "CREATE_PRODUCT_LOADING":
    case "UPDATE_CREATE_PRODUCT_LOADING":
    case "CREATE_CHARS_LOADING":
    case "UPDATE_CREATE_CHARS_LOADING":
    case "CREATE_EQUIPEMENT_LOADING":
    case "UPDATE_CREATE_EQUIPEMENT_LOADING":
    case "CREATE_LIGHTSENSOR_LOADING":
    case "UPDATE_CREATE_LIGHTSENSOR_LOADING":
    case "CREATE_ATTACHEMENTS_LOADING":
    case "UPDATE_CREATE_ATTACHEMENTS_LOADING":
    case "DELETE_ATTACHEMENT_LOADING":
    case "FETCH_CURRENT_PRODUCT_LOADING":
      return { ...state, currentProductLoading: true };

    case "CREATE_PRODUCT_SUCCESS":
    case "UPDATE_CREATE_PRODUCT_SUCCESS": {
      let isFound = false;
      const updatedProducts = state.products?.map((item) => {
        if (+item?.id === +payload?.id) {
          isFound = true;
          return payload;
        }
        return item;
      });
      return {
        ...state,
        products: isFound ? updatedProducts : [payload, ...state.products],
        currentProduct: payload,
        currentProductLoading: false,
      };
    }

    case "CREATE_CHARS_SUCCESS":
    case "UPDATE_CREATE_CHARS_SUCCESS": {
      const updatedProducts = state.products?.map((item) => {
        if (+item?.id === +state?.currentProduct?.id) {
          return {
            ...item,
            attributes: {
              ...item?.attributes,
              caracteristique: { data: payload },
            },
          };
        }
        return item;
      });
      return {
        ...state,
        products: updatedProducts,
        currentChars: payload,
        currentProductLoading: false,
      };
    }

    case "CREATE_EQUIPEMENT_SUCCESS":
    case "UPDATE_CREATE_EQUIPEMENT_SUCCESS": {
      const updatedProducts = state.products?.map((item) => {
        if (+item?.id === +state?.currentProduct?.id) {
          return {
            ...item,
            attributes: { ...item?.attributes, equipement: { data: payload } },
          };
        }
        return item;
      });
      return {
        ...state,
        products: updatedProducts,
        currentEquipement: payload,
        currentProductLoading: false,
      };
    }

    case "CREATE_LIGHTSENSOR_SUCCESS":
    case "UPDATE_CREATE_LIGHTSENSOR_SUCCESS": {
      const updatedProducts = state.products?.map((item) => {
        if (+item?.id === +state?.currentProduct?.id) {
          return {
            ...item,
            attributes: {
              ...item?.attributes,
              light_sensor_serie: { data: payload },
            },
          };
        }
        return item;
      });
      return {
        ...state,
        products: updatedProducts,
        currentLightSensor: payload,
        currentProductLoading: false,
      };
    }

    case "CREATE_ATTACHEMENTS_SUCCESS":
    case "UPDATE_CREATE_ATTACHEMENTS_SUCCESS": {
      const updatedProducts = state.products?.map((item) => {
        if (+item?.id === +state?.currentProduct?.id) {
          return {
            ...item,
            attributes: {
              ...item?.attributes,
              attachments: {
                data: [...state?.currentDocuments, ...payload],
              },
            },
          };
        }
        return item;
      });
      return {
        ...state,
        products: updatedProducts,
        currentAttachements: payload,
        currentProductLoading: false,
      };
    }

    case "DELETE_ATTACHEMENT_SUCCESS": {
      const updatedAttachements = state.currentAttachements?.filter(
        (attach) => {
          return !payload.find(
            (el) =>
              +attach?.attributes?.file?.data?.id === +el?.fileId &&
              +attach?.id === +el?.attachmentId,
          );
        },
      );

      const updatedProducts = state.products?.map((item) => {
        if (+item?.id === +state?.currentProduct?.id) {
          return {
            ...item,
            attributes: {
              ...item?.attributes,
              attachments: {
                data: [...state?.currentDocuments, ...updatedAttachements],
              },
            },
          };
        }
        return item;
      });

      return {
        ...state,
        products: updatedProducts,
        currentAttachements:
          updatedAttachements?.length > 0 ? updatedAttachements : [],
        currentProductLoading: false,
      };
    }

    case "CREATE_DOCUMENTS_SUCCESS":
    case "UPDATE_CREATE_DOCUMENTS_SUCCESS": {
      const updatedProducts = state.products?.map((item) => {
        if (+item?.id === +state?.currentProduct?.id) {
          return {
            ...item,
            attributes: {
              ...item?.attributes,
              attachments: {
                data: [...state.currentAttachements, ...payload],
              },
            },
          };
        }
        return item;
      });
      return {
        ...state,
        products: updatedProducts,
        currentDocuments: payload,
        currentProductLoading: false,
      };
    }

    case "DELETE_DOCUMENT_SUCCESS": {
      const updatedDocuments = state.currentDocuments?.filter((attach) => {
        return !payload.find(
          (el) =>
            +attach?.attributes?.file?.data?.id === +el?.fileId &&
            +attach?.id === +el?.attachmentId,
        );
      });
      const updatedProducts = state.products?.map((item) => {
        if (+item?.id === +state?.currentProduct?.id) {
          return {
            ...item,
            attributes: {
              ...item?.attributes,
              attachments: {
                data: [...state?.currentAttachements, ...updatedDocuments],
              },
            },
          };
        }
        return item;
      });
      return {
        ...state,
        products: updatedProducts,
        currentDocuments: updatedDocuments?.length > 0 ? updatedDocuments : [],
        currentProductLoading: false,
      };
    }

    case "FETCH_CURRENT_PRODUCT_SUCCESS": {
      const { attachments, caracteristique, equipement, light_sensor_serie } =
        payload?.attributes;

      const currentAttachements = attachments?.data?.filter(
        (item) =>
          item?.attributes?.file?.data?.attributes?.mime.includes("image") ||
          item?.attributes?.file?.data?.attributes?.mime.includes("video"),
      );
      const currentDocuments = attachments?.data?.filter(
        (item) =>
          !(
            item?.attributes?.file?.data?.attributes?.mime.includes("image") ||
            item?.attributes?.file?.data?.attributes?.mime.includes("video")
          ),
      );
      return {
        ...state,
        currentProductLoading: false,
        currentProduct: payload,
        currentChars: caracteristique?.data,
        currentLightSensor: light_sensor_serie?.data,
        currentEquipement: equipement?.data,
        currentAttachements,
        currentDocuments,
      };
    }
    case "DELETE_PRODUCT_LOADING":
      return {
        ...state,
        deleteLoading: true,
      };

    case "DELETE_PRODUCT_SUCCESS":
      return {
        ...state,
        products: state?.products.filter(
          (product) => +product?.id !== +payload,
        ),
        deleteLoading: false,
      };

    case "CREATE_PRODUCT_ERROR":
    case "UPDATE_CREATE_PRODUCT_ERROR":
    case "CREATE_CHARS_ERROR":
    case "UPDATE_CREATE_CHARS_ERROR":
    case "CREATE_EQUIPEMENT_ERROR":
    case "UPDATE_CREATE_EQUIPEMENT_ERROR":
    case "CREATE_LIGHTSENSOR_ERROR":
    case "UPDATE_CREATE_LIGHTSENSOR_ERROR":
    case "CREATE_ATTACHEMENTS_ERROR":
    case "CREATE_DOCUMENTS_ERROR":
    case "FETCH_CURRENT_PRODUCT_ERROR":
      return { ...state, currentProductLoading: false };

    case "CLEAR_CREATED_PRODUCT": {
      return {
        ...state,
        currentProduct: null,
        currentChars: null,
        currentLightSensor: null,
        currentEquipement: null,
        currentAttachements: [],
        currentDocuments: [],
        currentProductLoading: false,
      };
    }
    // for products dropdown
    case "PRODUCTS_LIST_LOADING": {
      return { ...state, productsListLoading: true };
    }
    case "PRODUCTS_LIST_FETCHED": {
      return { ...state, productsListLoading: false, productsList: payload };
    }
    case "PRODUCTS_LIST_ERROR": {
      return {
        ...state,
        productsListLoading: false,
        productsListError: payload,
      };
    }

    case "GET_RELATED_PRODUCTS": {
      return { ...state, relatedProducts: payload };
    }

    case "PRODUCTS_ACCESSORIES_LOADING": {
      return { ...state, accessories: [], accessoriesLoading: true };
    }
    case "PRODUCTS_ACCESSORIES_FETCHED": {
      return { ...state, accessories: payload, accessoriesLoading: false };
    }

    case "PRODUCTS_ACCESSORIES_ERROR": {
      return { ...state, accessoriesLoading: false };
    }

    default:
      return state;
  }
};

export default productsReducer;
