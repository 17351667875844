import React from "react";
import ViewScroller from "../Common/ViewScroller/ViewScroller";
import RightModal from "../Modals/RightModal/RightModal";
import CommandesCreditsViewContent from "./CommandesCreditsViewContent";

const CommandesCreditsTabContent = ({ activeTab }) => {
  return (
    <ViewScroller>
      <CommandesCreditsViewContent activeTab={activeTab} />
      <RightModal width={80} className="pb-4" />
    </ViewScroller>
  );
};

export default CommandesCreditsTabContent;
