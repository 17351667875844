import React from "react";

import CommandesTabContent from "./CommandesTabContent";
import CommandesCreditsTabContent from "./CommandesCreditsTabContent";
import ApprovTabContent from "./Approvisionnement/ApprovTabContent";

function CommandesViewTabsContent({ activeTab }) {
  switch (activeTab) {
    case "Orders":
      return <CommandesTabContent activeTab={activeTab} />;
    case "Credits":
      return <CommandesCreditsTabContent activeTab={activeTab} />;
    case "Supplies":
      return <ApprovTabContent activeTab={activeTab} />;
    default:
      return <CommandesTabContent />;
  }
}

export default CommandesViewTabsContent;
