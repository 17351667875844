import QueryString from "qs";

export const productsListQuery = (marqueId) =>
  QueryString.stringify(
    {
      fields: [
        "id",
        "name",
        "name_en",
        "retailerPriceHT",
        "TVA",
        "publicPriceTTC",
        "productType",
      ],
      filters: {
        marque: { id: { $eq: 2 } },
      },
    },
    { encodeValuesOnly: true }
  );
