import React from "react";
import { Calendar } from "primereact/calendar";
import "./DeliveryCalendar.css";

import "./DeliveryCalendar.css";
import moment from "moment";
import { useTranslation } from "react-i18next";

function DeliveryCalendar({ setSelectedDate, selectedDate, orderStatus }) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const handleDateChange = (e) => {
    setSelectedDate(moment(e.value).format("YYYY-MM-DD"));
  };

  return (
    <div className="calendarContainer">
      <Calendar
        inline
        value={moment(selectedDate).utc(true).toDate()}
        onChange={handleDateChange}
        locale={currentLanguage?.includes("en") ? "en" : "fr"}
        className="deliveryCalendar"
        dateFormat="dd/mm/yy"
        minDate={false}
        // minDate={
        //   orderStatus === "Livrée"
        //     ? false
        //     : moment().add(5, "days").utc(true).toDate()
        // }
      />
    </div>
  );
}

export default DeliveryCalendar;
