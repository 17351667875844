import qs from "qs";
import API from "../../Api/Api";
import { API_END_POINT } from "../../Api/EndPoints";
import { deleteRecord } from "../../Utils/api";
import { isDefined } from "../../Utils";
import { toast } from "react-toastify";
import { updateDelivery } from "./panier";
import store from "../store";
import _ from "lodash";

export const getResellers =
  (search, reset = false, isArchived = false) =>
  async (dispatch) => {
    try {
      if (reset) dispatch({ type: "RESET_RESELLERS" });
      const { resellers: currentResellers, resellersCount } =
        store.getState().resellerReducer;

      const currentPage = Math.ceil(currentResellers?.length / 10);
      const page = currentPage + 1;
      if (
        (isDefined(resellersCount) && currentPage * 10 >= resellersCount) ||
        resellersCount === 0
      )
        return;
      dispatch({ type: "FETCH_RESELLERS_LOADING" });
      const { userRole, user } = store.getState().userReducer;
      const businessBrokerId = user?.attributes?.business_affiliate?.data?.id;
      const userMarqueId = user?.attributes?.marque?.data?.id;
      const filters = {
        name: { $containsi: search },
        marque: { id: { $eq: userMarqueId } },
      };
      // if the userRole is a businessBroker then add its filters with assing Object js method
      if (isArchived) {
        Object.assign(filters, {
          archived: { $eq: true },
        });
      } else if (isArchived === false) {
        Object.assign(filters, {
          $or: [
            {
              archived: { $eq: false },
            },
            {
              archived: { $null: true },
            },
          ],
        });
      }

      if (userRole === "BusinessBroker") {
        Object.assign(filters, {
          business_affiliate: { id: { $eq: businessBrokerId } },
        });
      }
      const query = qs.stringify({
        populate: [
          "business_affiliate.user",
          "profils",
          "delivery_addresses.city",
          "delivery_addresses.country",
          "billingAddress.city",
          "billingAddress.country",
          "orders",
          "commandes",
        ],
        filters: filters,
      });
      const {
        data: { data: resellerData, meta },
      } = await API.get(
        `${API_END_POINT}api/organizations?${query}&sort[0]=createdAt:desc&pagination[page]=${page}`,
        {
          params: {
            pagination: {
              pageSize: 10,
            },
          },
        }
      );
      dispatch({
        type: "FETCH_RESELLERS_SUCCESS",
        payload:
          page === 1
            ? resellerData
            : _.uniqBy([...currentResellers, ...resellerData], function (item) {
                return item?.id;
              }),
      });
      dispatch({
        type: "RESELLERS_COUNT",
        payload: meta?.pagination?.total,
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: "FETCH_RESELLERS_ERROR", payload: error });
    }
  };

export const getCurrentReseller = (id) => async (dispatch) => {
  try {
    if (!id) return;
    dispatch({ type: "FETCH_CURRENT_RESELLER_LOADING" });
    const query = qs.stringify({
      populate: [
        "business_affiliate.user",
        "profils",
        "commandes",
        "billingAddress.city",
        "billingAddress.country",
        "delivery_addresses.city",
        "delivery_addresses.country",
        "orders.delivery",
      ],
    });
    const {
      data: { data: resellerData },
    } = await API.get(`${API_END_POINT}api/organizations/${id}?${query}`);

    dispatch({ type: "FETCH_CURRENT_RESELLER_SUCCESS", payload: resellerData });
  } catch (error) {
    dispatch({ type: "FETCH_CURRENT_RESELLER_ERROR", payload: error });
  }
};

export const createUpdateReseller =
  ({ infos, addresses, affiliate }, id, callback = () => {}, t) =>
  async (dispatch, getState) => {
    try {
      const { user } = getState().userReducer;
      dispatch({ type: "CREATE_UPDATE_RESELLER_LOADING" });
      const query = qs.stringify({
        populate: [
          "business_affiliate.user",
          "profils",
          "delivery_addresses.city",
          "delivery_addresses.country",
          "billingAddress.city",
          "billingAddress.country",
        ],
      });

      const {
        data: { data: createdReseller },
      } = await API[isDefined(id) ? "put" : "post"](
        `${API_END_POINT}api/organizations/${id ?? ""}?${query}`,
        {
          data: {
            ...infos,
            marque: user?.attributes?.marque?.data?.id,
          },
        }
      );

      await dispatch(
        createUpdateAddress(
          addresses.delivery,
          addresses.billing,
          createdReseller?.id
        )
      );
      // await dispatch(
      //   updateResellerAffiliates(
      //     affiliate
      //       .map((item) => item?.code)
      //       ?.filter((item) => isDefined(item?.code)),
      //     createdReseller?.id
      //   )
      // );

      await dispatch({
        type: "CREATE_UPDATE_RESELLER_SUCCESS",
        payload: createdReseller,
      });
      callback();

      if (isDefined(id)) {
        toast.success(t("The reseller has been updated successfully!"));
      } else {
        toast.success(t("Reseller created successfully!"));
      }
    } catch (error) {
      console.log({ error });
      if (isDefined(id)) {
        toast.error(t("An error occurred while updating the reseller"));
      } else {
        toast.error(t("An error occurred while creating the reseller"));
      }
      dispatch({ type: "CREATE_UPDATE_RESELLER_ERROR", payload: error });
    }
  };

export const createUpdateAddress =
  (formData, secondFormData, id) => async (dispatch, getState) => {
    try {
      const { currentReseller } = getState().resellerReducer;
      dispatch({ type: "CREATE_RESELLER_ADDRESS_LOADING" });
      const query = qs.stringify({
        populate: ["country", "city", "reseller"],
      });
      const deliveryAddressId =
        currentReseller?.attributes?.delivery_addresses?.data[0]?.id;
      const deliveryAddressIds =
        currentReseller?.attributes?.delivery_addresses?.data
          ?.filter((item) => {
            return deliveryAddressId !== item?.id && isDefined(item?.id);
          })
          ?.map(({ id }) => id);

      const {
        data: { data: deliveryAddressData },
      } = await API[isDefined(deliveryAddressId) ? "put" : "post"](
        `${API_END_POINT}api/addresses/${deliveryAddressId ?? ""}?${query}`,
        {
          data: {
            ...formData,
            country: formData?.country?.code,
            city: formData?.city?.code,
          },
        }
      );

      const billingAddressId =
        currentReseller?.attributes?.billingAddress?.data[0]?.id;

      const {
        data: { data: billingAddressData },
      } = await API[isDefined(billingAddressId) ? "put" : "post"](
        `${API_END_POINT}api/addresses/${billingAddressId ?? ""}?${query}`,
        {
          data: {
            ...secondFormData,
            country: secondFormData?.country?.code,
            city: secondFormData?.city?.code,
          },
        }
      );
      await API.put(`${API_END_POINT}api/organizations/${id}`, {
        data: {
          delivery_addresses: [deliveryAddressData?.id, ...deliveryAddressIds],
          billingAddress: billingAddressData?.id,
        },
      });

      await dispatch({
        type: "CREATE_RESELLER_ADDRESS_SUCCESS",
        payload: { billingAddressData, deliveryAddressData },
      });
    } catch (error) {
      dispatch({ type: "CREATE_RESELLER_ADDRESS_ERROR", payload: error });
      console.log(error);
    }
  };

export const updateResellerAffiliates =
  (affiliates = [], id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "CREATE_UPDATE_RESELLER_LOADING" });
      const query = qs.stringify({
        populate: [
          "business_affiliate.user",
          "profils",
          "delivery_addresses.city",
          "delivery_addresses.country",
          "billingAddress.city",
          "billingAddress.country",
        ],
      });

      const {
        data: { data: resellerData },
      } = await API.put(`${API_END_POINT}api/organizations/${id}?${query}`, {
        data: {
          business_affiliates: affiliates,
        },
      });
      dispatch({
        type: "CREATE_UPDATE_RESELLER_SUCCESS",
        payload: resellerData,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "CREATE_UPDATE_RESELLER_ERROR",
        payload: error,
      });
    }
  };

export const deleteReseller =
  (id, deliveryAddressId, billingAddressId, t) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_RESELLER_LOADING" });
      await deleteRecord(id, "organizations");
      await deleteRecord(deliveryAddressId, "addresses");
      await deleteRecord(billingAddressId, "addresses");
      dispatch({ type: "DELETE_RESELLER_SUCCESS", payload: id });
      toast.success(t("Reseller deleted successfully!"));
    } catch (error) {
      toast.error(
        t(
          "Reseller deleted successfully! An error occurred while deleting the reseller"
        )
      );
      dispatch({ type: "DELETE_RESELLER_ERROR", payload: error });
    }
  };
export const resetCurrentReseller = () => (dispatch) => {
  dispatch({ type: "RESET_RESELLER" });
};

export const getSelectedReselerAdress = (payload) => async (dispatch) => {
  const { id } = payload;
  const panier = store.getState().panierReducer.panier;

  try {
    dispatch({ type: "FETCH_SELECTED_RESELLER_ADRESS" });
    const {
      data: { data },
    } = await API.get(`api/addresses/${id}?populate=*`);

    const street = data?.attributes?.street;
    const zipCode = data?.attributes?.zip;
    const city = data?.attributes?.city?.data?.attributes?.name;
    const country = data?.attributes?.country?.data?.attributes?.name;

    dispatch(
      updateDelivery({
        deliveryID: panier?.delivery?.id,
        addressId: id,
        panierID: panier?.id,
        billingAddress: `${street}, ${zipCode} ${city}, ${country}`,
        deliveryAddress: `${street}, ${zipCode} ${city}, ${country}`,
      })
    );
    dispatch({
      type: "SELECTED_RESELLER_ADRESS_SUCCESS",
      // payload: selectedAdress,
    });
  } catch (error) {
    dispatch({ type: "SELECTED_RESELLER_ADRESS_FAIL", payload: error });
    toast.error("un erreur est servenue");
    console.log(error);
  }
};

export const archiveReseller = (id) => async (dispatch) => {
  if (!isDefined(id)) return;
  try {
    await API.put(`${API_END_POINT}api/organizations/${id}`, {
      data: {
        archived: true,
      },
    });
    dispatch({ type: "RESELLER_ARCHIVED", payload: id });
  } catch (err) {
    console.log(err);
  }
};

export const unarchiveReseller = (id) => async (dispatch) => {
  if (!isDefined(id)) return;
  try {
    await API.put(`${API_END_POINT}api/organizations/${id}`, {
      data: {
        archived: false,
      },
    });
    dispatch({ type: "RESELLER_UNARCHIVED", payload: id });
  } catch (err) {
    console.log(err);
  }
};
