import { API_END_POINT } from "../../Api/EndPoints";
import API from "../../Api/Api";

export const getModels = (language) => async (dispatch) => {
  dispatch({ type: "FETCH_MODELS_LOADING" });
  try {
    const { data } = await API.get(`${API_END_POINT}api/products`);

    const modelsMap = data?.data?.map((item) => ({
      name:
        language === "en"
          ? item?.attributes?.name_en ?? item?.attributes?.name
          : item?.attributes?.name,
      code: item?.id,
      productType: item?.attributes?.productType,
      retailerPriceHT: item?.attributes?.retailerPriceHT,
      publicPriceTTC: item?.attributes?.publicPriceTTC,
    }));

    dispatch({
      type: "FETCH_MODELS_SUCCESS",
      payload: { modelInfos: data?.data, modelsMap },
    });
  } catch (error) {
    dispatch({ type: "FETCH_MODELS_ERROR" });
  }
};
