import "./App.css";
import "./custom.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes/Routes";
import AuthCheck from "./Components/AuthCheck/AuthCheck";
import Layout from "./Components/Layout/Layout";
import ErrorBoundary from "./Utils/ErrorBoundary/ErrorBoundary";
import { ToastContainer } from "react-toastify";
import CenteredModal from "./Components/Modals/CenteredModal/CenteredModal";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useLayoutEffect } from "react";
import { getCategories } from "./REDUX/actions/category";
import { getRanges } from "./REDUX/actions/range";
import { getProductSizes } from "./REDUX/actions/productSize";
import { getDeliveryTypes } from "./REDUX/actions/deliveryTypes";
import { fetchProfiles } from "./REDUX/actions/generalData";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { addLocale } from "primereact/api";
import { days, months, shortDays } from "./helpers/fakeData/Calendar/Dates";

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const { locale } = useSelector((state) => state.localeReducer);
  console.log({ locale });

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  useLayoutEffect(() => {
    dispatch({
      type: "SET_LOCALE",
      payload: currentLanguage?.includes("en") ? "en" : "fr",
    });
  }, [currentLanguage, dispatch]);

  useEffect(() => {
    moment.locale(currentLanguage);
  }, [currentLanguage]);

  addLocale("fr", {
    dayNames: days["fr"],
    dayNamesShort: shortDays["fr"],
    dayNamesMin: shortDays["fr"],
    monthNames: months["fr"],
    monthNamesShort: months["fr"],
    firstDayOfWeek: 1,
  });
  addLocale("en", {
    dayNames: days["en"],
    dayNamesShort: shortDays["en"],
    dayNamesMin: shortDays["en"],
    monthNames: months["en"],
    monthNamesShort: months["en"],
    firstDayOfWeek: 1,
  });

  useEffect(() => {
    if (user) {
      dispatch(getCategories());
      dispatch(getRanges(i18n.language));
      dispatch(getProductSizes());
      dispatch(getDeliveryTypes());
      dispatch(fetchProfiles());
      // dispatch(fetchCommandes());
    }
  }, [dispatch, user, i18n.language]);

  return (
    <div className="App">
      <ErrorBoundary>
        <BrowserRouter>
          <AuthCheck>
            <Layout>
              <Routes />
              <CenteredModal />
            </Layout>
          </AuthCheck>
          <ToastContainer />
        </BrowserRouter>
      </ErrorBoundary>
    </div>
  );
}

export default App;
