import qs from "qs";
import { toast } from "react-toastify";
import API from "../../Api/Api";
import { closeRightModal } from "./rightModal";
import { uploadAttachmentToStrapi } from "../../Utils/uploadAttachmentToStrapi";
import { approvsQuery } from "../apiQueries/approvisionnement";
import {
  approvCore,
  approvsExtractor,
  oneApprovExtractor,
} from "../../Utils/DataExtractors/approvsExtractor";
import moment from "moment";
import store from "../store";
import { uploadFile } from "../../Utils";
import { deleteRecord } from "../../Utils/api";
import { API_END_POINT } from "../../Api/EndPoints";
import _ from "lodash";
import axios from "axios";
import { addToCart } from "./catalogue";

// CREATE APPROV
export const createApprov = (payload, t) => async (dispatch, getState) => {
  dispatch({ type: "APPROV_LOADING" });
  try {
    const { user } = getState().userReducer;
    const convertDate = (date) => moment(date)?.utc(true).toDate();
    const { data: createdApprov } = await API.post(
      `api/approvisionnements?populate=*`,
      {
        data: {
          ...payload.container,
          orderDate: payload.container?.orderDate
            ? convertDate(payload.container?.orderDate)
            : null,
          arrivalDate: payload.container?.arrivalDate
            ? convertDate(payload.container?.arrivalDate)
            : null,
          estimatedDate: payload.container?.estimatedDate
            ? convertDate(payload.container?.estimatedDate)
            : null,
          QC: payload?.packingList?.reduce(
            (acc, curr) => acc + parseFloat(curr?.qc || 0),
            0
          ),
          amountTTC: +payload?.container?.amountTTC,
          realEntrepot: payload?.container?.realEntrepot?.id,
          marque: user?.attributes?.marque?.data?.id,
        },
      }
    );

    const createPacking = async (data) => {
      const { id, ...rest } = data;

      const {
        data: { data: fetchedPdt },
      } = await API.get(`api/products/${rest?.model?.code}`);

      return API.post("api/packings", {
        data: {
          ...rest,
          model: rest.model?.code,
          panel: rest.panel?.code,
          reference: rest.reference,
          cuve: rest.cuve?.code,
          approvisionnement: createdApprov.data.id,
          retailerPriceHT: fetchedPdt?.attributes?.retailerPriceHT,
          publicPriceTTC: fetchedPdt?.attributes?.publicPriceTTC,
          entrepot: rest?.entrepot?.id,
        },
      });
    };

    const updatePacking = (data) => {
      return API.put(`api/packings/${data.id}`, {
        data: {
          ...data,
          model: data.model?.code,
          panel: data.panel?.code,
          reference: data.reference,
          cuve: data.cuve?.code,
          approvisionnement: createdApprov.data.id,
          // retailerPriceHT: data.model?.retailerPriceHT,
          // publicPriceTTC: data.model?.publicPriceTTC,
        },
      });
    };

    for (const packingItem of payload.packingList) {
      if (packingItem.newPacking) {
        const quantity = +packingItem?.quantity;
        if (quantity > 1 && !packingItem?.isProduct) {
          const savedItems =
            createdApprov?.data?.attributes?.packings?.data?.filter(
              (item) => +item?.model?.data?.id === +packingItem?.model?.code
            );
          const savedItemsQuantity = savedItems?.length;
          const hasIncreasedQuantity = quantity - savedItemsQuantity >= 0;
          if (hasIncreasedQuantity) {
            for (let i = 1; i <= quantity - savedItemsQuantity; i++) {
              await createPacking(packingItem);
            }
          } else {
            for (let i = 1; i <= savedItemsQuantity - quantity; i++) {
              const recordId = savedItems[savedItemsQuantity - i]?.id;
              await deleteRecord(recordId, "packings");
              _.remove(payload.packingList, function (obj) {
                return +obj?.id === +recordId;
              });
              _.remove(
                createdApprov?.data?.attributes?.packings?.data,
                function (obj) {
                  return +obj?.id === +recordId;
                }
              );
            }
          }
        } else {
          await createPacking(packingItem);
        }
      } else {
        const quantity = +packingItem?.quantity;
        if (quantity > 1 && !packingItem?.isProduct) {
          await updatePacking(packingItem);
          const savedItems =
            createdApprov?.data?.attributes?.packings?.data?.filter(
              (item) => +item?.model?.data?.id === +packingItem?.model?.code
            );
          const savedItemsQuantity = savedItems?.length;
          const hasIncreasedQuantity = quantity - savedItemsQuantity >= 0;
          if (hasIncreasedQuantity) {
            for (let i = 1; i <= quantity - savedItemsQuantity; i++) {
              await createPacking(packingItem);
            }
          } else {
            for (let i = 1; i <= savedItemsQuantity - quantity; i++) {
              const recordId = savedItems[savedItemsQuantity - i]?.id;
              await deleteRecord(recordId, "packings");
              _.remove(payload.packingList, function (obj) {
                return +obj?.id === +recordId;
              });
              _.remove(
                createdApprov?.data?.attributes?.packings?.data,
                function (obj) {
                  return +obj?.id === +recordId;
                }
              );
            }
          }
        } else {
          await updatePacking(packingItem);
        }
      }
    }

    for (const currPacking of createdApprov?.data?.attributes?.packings?.data) {
      if (payload.packingList.every((item) => +item?.id !== +currPacking?.id)) {
        await deleteRecord(currPacking?.id, "packings");
      }
    }

    if (payload.attachments.length) {
      for (const attachment of payload.attachments) {
        if (attachment.file) {
          const { data: createdAttachment } = await API.post(
            "api/attachments",
            {
              data: {
                title: attachment.title,
                approvisionnement: createdApprov.data.id,
              },
            }
          );
          uploadAttachmentToStrapi(
            attachment.file,
            "approvisionnementfichier",
            createdAttachment.data.id,
            "api::attachment.attachment",
            "file"
          );
        }
      }
    }
    if (payload.payments.length) {
      for (const payment of payload.payments) {
        if (payment.newPayment) {
          await API.post(`api/payments`, {
            data: {
              amount: payment.amount,
              date: moment(payment.date).format("YYYY-MM-DD"),
              approvisionnement: createdApprov.data.id,
            },
          });
        } else {
          await API.put(`api/payments/${payment.id}`, {
            data: {
              amount: payment.amount,
              date: moment(payment.date).format("YYYY-MM-DD"),
            },
          });
        }
      }
    }

    const { data } = await API.get(
      `api/approvisionnements/${createdApprov?.data?.id}?populate=*,packings,packings.model,payments,attachments,comments,invoice,realEntrepot`
    );

    dispatch({
      type: "APPROV_CREATED",
      payload: approvCore(data?.data),
    });
    // dispatch({ type: "APPROVS_RELOAD" });
    dispatch(closeRightModal());
    toast.success(t("Supply successfully created!"));
  } catch (error) {
    console.log(error);
    dispatch({ type: "APPROV_ERROR", payload: error });
    toast.error(t("An error has occurred!"));
  }
};

// UPDATE APPROV
export const updateApprov = (payload, t) => async (dispatch) => {
  const { approv } = store.getState().approvReducer;
  dispatch({ type: "APPROV_LOADING" });
  // return;
  try {
    const convertDate = (date) => moment(date)?.utc(true).toDate();
    const { data: createdApprov } = await API.put(
      `api/approvisionnements/${payload.id}`,
      {
        data: {
          ...payload.container,
          orderDate: payload.container?.orderDate
            ? convertDate(payload.container?.orderDate)
            : null,
          arrivalDate: payload.container?.arrivalDate
            ? convertDate(payload.container?.arrivalDate)
            : null,
          estimatedDate: payload.container?.estimatedDate
            ? convertDate(payload.container?.estimatedDate)
            : null,
          // QC: payload?.container?.QC ? payload?.container?.QC : 0,
          QC: payload?.packingList?.reduce((acc, curr) => {
            return acc + parseFloat(curr?.qc || 0);
          }, 0),
          amountTTC: +payload?.container?.amountTTC,
          realEntrepot: payload?.container?.realEntrepot?.id || null,
        },
      }
    );
    const createPacking = (data) => {
      const { id, ...rest } = data;

      return API.post("api/packings", {
        data: {
          ...rest,
          model: rest.model?.code,
          panel: rest.panel?.code,
          reference: rest.reference,
          cuve: rest.cuve?.code,
          approvisionnement: createdApprov.data.id,
          retailerPriceHT: rest.model?.retailerPriceHT,
          publicPriceTTC: rest.model?.publicPriceTTC,
        },
      });
    };
    const updatePacking = (data) => {
      return API.put(`api/packings/${data.id}`, {
        data: {
          ...data,
          model: data.model?.code,
          panel: data.panel?.code,
          reference: data.reference,
          cuve: data.cuve?.code,
          approvisionnement: createdApprov.data.id,
        },
      });
    };

    for (const packingItem of payload.packingList) {
      if (packingItem.newPacking) {
        const quantity = +packingItem?.quantity;
        if (quantity > 1 && !packingItem?.isProduct) {
          const savedItems = approv.packingList?.filter(
            (item) => +item?.model?.data?.id === +packingItem?.model?.code
          );
          const savedItemsQuantity = savedItems?.length;
          const hasIncreasedQuantity = quantity - savedItemsQuantity >= 0;
          if (hasIncreasedQuantity) {
            for (let i = 1; i <= quantity - savedItemsQuantity; i++) {
              await createPacking(packingItem);
            }
          } else {
            for (let i = 1; i <= savedItemsQuantity - quantity; i++) {
              const recordId = savedItems[savedItemsQuantity - i]?.id;
              await deleteRecord(recordId, "packings");
              _.remove(payload.packingList, function (obj) {
                return +obj?.id === +recordId;
              });
              _.remove(approv.packingList, function (obj) {
                return +obj?.id === +recordId;
              });
            }
          }
        } else {
          await createPacking(packingItem);
        }
      } else {
        const quantity = +packingItem?.quantity;
        if (quantity > 1 && !packingItem?.isProduct) {
          await updatePacking(packingItem);
          const savedItems = approv.packingList?.filter(
            (item) => +item?.model?.data?.id === +packingItem?.model?.code
          );
          const savedItemsQuantity = savedItems?.length;
          const hasIncreasedQuantity = quantity - savedItemsQuantity >= 0;
          if (hasIncreasedQuantity) {
            for (let i = 1; i <= quantity - savedItemsQuantity; i++) {
              await createPacking(packingItem);
            }
          } else {
            for (let i = 1; i <= savedItemsQuantity - quantity; i++) {
              const recordId = savedItems[savedItemsQuantity - i]?.id;
              await deleteRecord(recordId, "packings");
              _.remove(payload.packingList, function (obj) {
                return +obj?.id === +recordId;
              });
              _.remove(approv.packingList, function (obj) {
                return +obj?.id === +recordId;
              });
            }
          }
        } else {
          await updatePacking(packingItem);
        }
      }
    }

    for (const currPacking of approv.packingList) {
      if (payload.packingList.every((item) => +item?.id !== +currPacking?.id)) {
        await deleteRecord(currPacking?.id, "packings");
      }
    }

    if (payload.attachments.length) {
      const oldAttachments = store.getState().approvReducer.approv.attachments;
      for (const attachement of payload.attachments) {
        // add or update attachment
        const existantFile = oldAttachments.find(
          (oldAttach) => oldAttach.id === attachement.id
        );

        if (!existantFile && attachement.file) {
          const fileId = await uploadFile(attachement?.file);
          await API.post(`api/attachments?populate=*`, {
            data: {
              title: attachement?.title,
              date: new Date(),
              file: fileId,
              approvisionnement: payload.id,
            },
          });
        } else if (existantFile && attachement.title !== existantFile?.title) {
          await API.put(
            `api/attachments/${attachement?.attachmentId}?populate=*`,
            {
              data: {
                title: attachement?.title,
              },
            }
          );
        }
      }
    }
    if (payload.payments.length) {
      for (const payment of payload.payments) {
        if (payment.newPayment) {
          await API.post(`api/payments`, {
            data: {
              amount: payment.amount,
              date: moment(payment.date).format("YYYY-MM-DD"),
              approvisionnement: createdApprov.data.id,
            },
          });
        } else {
          await API.put(`api/payments/${payment.id}`, {
            data: {
              amount: payment.amount,
              date: moment(payment.date).format("YYYY-MM-DD"),
            },
          });
        }
      }
    }

    const { data } = await API.get(
      `api/approvisionnements/${createdApprov?.data?.id}?populate=*,packings,packings.model,payments,attachments,comments,invoice,realEntrepot`
    );
    dispatch({
      type: "APPROV_UPDATED",
      payload: approvCore(data?.data),
    });
    // dispatch({ type: "APPROVS_RELOAD" });
    dispatch(closeRightModal());
    toast.success(t("Supply successfully updated!"));
  } catch (error) {
    console.log(error);
    dispatch({ type: "APPROV_ERROR", payload: error });
    toast.error(t("An error has occurred!"));
  }
};

export const getApprovs = (payload) => async (dispatch, getState) => {
  dispatch({ type: "APPROVS_LOADING" });
  try {
    const page = payload?.page || 1;
    const { user } = getState().userReducer;

    if (page === 1) {
      dispatch({ type: "APPROVS_LOADING" });
    } else {
      dispatch({ type: "APPROVS_REFETCH_LOADING" });
    }
    const fetchUrl = `${API_END_POINT}api/approvisionnements?${approvsQuery({
      factory: payload.factory,
      searchInput: payload.searchInput,
      year: payload.year,
      month: payload.month,
      page,
      userMarque: user?.attributes?.marque?.data?.id,
    })}&pagination[page]=${page}&pagination[pageSize]=10`;

    const { data: allApprovs } = await axios.get(fetchUrl);

    const orders = approvsExtractor(allApprovs.data);

    if (page === 1) {
      dispatch({
        type: "APPROVS_FETCHED",
        payload: { orders, totalCount: allApprovs.totalCount },
      });
    } else {
      dispatch({ type: "APPROVS_SUCCESS_FETCHED", payload: orders });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "APPROVS_ERROR" });
  }
};

export const getApprovsSelect = () => async (dispatch) => {
  dispatch({ type: "APPROVS_SELECT_LOADING" });
  try {
    const fetchUrl = `api/approvisionnements?${approvsQuery({})}`;
    const { data: allApprovs } = await API.get(fetchUrl);
    const orders = approvsExtractor(allApprovs.data);
    dispatch({ type: "APPROV_SELECT_FETCHED", payload: orders });
  } catch (error) {
    console.log(error);
    dispatch({ type: "APPROVS_ERROR" });
  }
};

export const getOneApprov = (payload) => async (dispatch) => {
  dispatch({ type: "APPROV_LOADING" });
  try {
    const fetchUrl = `${API_END_POINT}api/approvisionnements/${
      payload?.id
    }?${approvsQuery("")}`;
    const { data: oneApprov } = await API.get(fetchUrl);
    const approv = oneApprovExtractor(oneApprov.data);
    dispatch({ type: "APPROV_FETCHED", payload: approv });
  } catch (error) {
    console.log(error);
    dispatch({ type: "APPROV_ERROR", payload: error });
  }
};

export const deleteApprov = (payload) => async (dispatch) => {
  dispatch({ type: "APPROVS_LOADING" });
  try {
    const packingList = payload?.packingList;
    if (packingList?.length > 0) {
      for (const packing of packingList) {
        await deleteRecord(packing?.id, "packings");
      }
      // if (isDefined(packingList[0]?.model?.data?.id)) {
      //   const newQuantity =
      //     packingList[0]?.model?.data?.attributes?.availableQuantity -
      //     (packingList?.length ?? 0);

      //   await API.put(`api/products/${packingList[0]?.model?.data?.id}`, {
      //     data: {
      //       availableQuantity: newQuantity > 0 ? newQuantity : 0,
      //     },
      //   });
      // }
    }
    await API.delete(`api/approvisionnements/${payload.id}`);
    dispatch({
      type: "APPROV_DELETED",
      payload: payload.id,
    });

    // dispatch({ type: "APPROVS_RELOAD" });
  } catch (error) {
    console.log(error);
    dispatch({ type: "APPROVS_ERROR", payload: error });
  }
};

// COMMENTS

export const getContainerComments = (approvisionnement) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_COMMENTS_CONTAINER_LOADING" });
    const query = qs.stringify({
      populate: [
        "attachments.file",
        "user.photo",
        "approvisionnement",
        "user.profil.reseller",
        "user.business_affiliate",
      ],
      sort: ["createdAt:desc"],
      filters: {
        approvisionnement: { id: { $eq: approvisionnement } },
      },
    });
    const {
      data: { data: commentData },
    } = await API.get(`${API_END_POINT}api/comments?${query}`);
    dispatch({
      type: "FETCH_COMMENTS_CONTAINER_SUCCESS",
      payload: commentData,
    });
  } catch (error) {
    dispatch({ type: "FETCH_COMMENTS_CONTAINER_ERROR", payload: error });
  }
};

export const createContainerComment =
  (comment, approvisionnement, user, successCallback, t) =>
  async (dispatch) => {
    dispatch({ type: "CREATE_COMMENTS_CONTAINER_LOADING" });

    try {
      const {
        data: { data: commentData },
      } = await API.post(`${API_END_POINT}api/comments?populate=*`, {
        data: {
          text: comment?.text,
          approvisionnement,
          user,
        },
      });

      if (comment?.attachments?.length > 0) {
        for (const file of comment?.attachments) {
          const fileId = await uploadFile(file);
          const { data } = await API.post(
            `${API_END_POINT}api/attachments?populate=*`,
            {
              data: {
                date: new Date(),
                file: fileId,
                comment: commentData?.id,
              },
            }
          );
          commentData.attributes.attachments.data?.push(data?.data);
        }
      }

      dispatch({
        type: "CREATE_COMMENTS_CONTAINER_SUCCESS",
        payload: {
          ...commentData,
          attributes: { ...commentData?.attributes },
        },
      });
      successCallback();
      toast.success(t("A comment has been added"));
    } catch (error) {
      console.log(error);
    }
  };

export const addServPackingToCart =
  (
    modelId,
    { customRetailerPriceHT, customPublicPriceTTC },
    { activeTab, searchInput, productsactiveTab }
  ) =>
  async (dispatch) => {
    dispatch({ type: "CREATE_SERV_PACKING_LOAD" });
    try {
      const {
        data: { data: fetchedPdt },
      } = await API.get(`api/products/${modelId}`);

      const {
        data: { data: packing },
      } = await API.post("api/packings", {
        data: {
          model: modelId,
          reference: fetchedPdt?.attributes?.reference,
          retailerPriceHT: customRetailerPriceHT
            ? customRetailerPriceHT
            : fetchedPdt?.attributes?.retailerPriceHT,
          publicPriceTTC: customPublicPriceTTC
            ? customPublicPriceTTC
            : fetchedPdt?.attributes?.publicPriceTTC,
          name: fetchedPdt?.attributes?.name,
        },
      });

      dispatch(
        addToCart(packing?.id, 1, {
          activeTab,
          searchInput,
          productsactiveTab,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch({ type: "CREATE_SERV_PACKING_ERROR", payload: error });
    }
  };
