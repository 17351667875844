import { combineReducers } from "redux";
import { userReducer } from "./user.js";
import calendarReducer from "./calendar.js";
import rightModalReducer from "./rightModal.js";
import centeredModalReducer from "./centeredModal.js";
import panierReducer from "./panier.js";
import errorReducer from "./error.js";
import productsReducer from "./products.js";
import ordersReducer from "./orders.js";
import invoicesReducer from "./invoices.js";
import prospectsReducer from "./prospects.js";
import ticketSAVReducer from "./ticketSAV.js";
import guidesReducer from "./guides.js";
import categoryReducer from "./category.js";
import rangeReducer from "./range.js";
import organizationReducer from "./organization.js";
import countryReducer from "./country.js";
import affiliateReducer from "./affiliate.js";
import modelReducer from "./model.js";
import questionsPopupReducer from "./questionsPopup.js";
import expensesReducer from "./expenses.js";
import resellerReducer from "./reseller.js";
import profileReducer from "./profile.js";
import approvReducer from "./approvisionnement.js";
import eventsReducer from "./events.js";
import statsReducer from "./stats.js";
import deliveryTypeReducer from "./deliveryTypes.js";
import productSizeReducer from "./productSizes.js";
import catalogueReducer from "./catalogue.js";
import generalReducer from "./generalData.js";
import commandeReducer from "./commandes.js";
import entrepotReducer from "./entrepot.js";
import localeReducer from "./locale.js";

const rootReducer = combineReducers({
  userReducer,
  commandeReducer,
  productsReducer,
  generalReducer,
  panierReducer,
  catalogueReducer,
  ordersReducer,
  invoicesReducer,
  prospectsReducer,
  ticketSAVReducer,
  rightModalReducer,
  calendarReducer,
  centeredModalReducer,
  errorReducer,
  guidesReducer,
  categoryReducer,
  rangeReducer,
  organizationReducer,
  countryReducer,
  modelReducer,
  affiliateReducer,
  questionsPopupReducer,
  expensesReducer,
  resellerReducer,
  profileReducer,
  approvReducer,
  eventsReducer,
  statsReducer,
  deliveryTypeReducer,
  productSizeReducer,
  entrepotReducer,
  localeReducer,
});
export default rootReducer;
