import React, { useEffect, useState } from "react";
import NewDepenseGeneralInfo from "./NewDepenseGeneralInfo";
import NewDepenseCommentaire from "./NewDepenseCommentaire";
import { useDispatch, useSelector } from "react-redux";
import { attachmentsDepListData } from "../../../helpers/fakeData/Sav/attachments";
import Paiements from "../../Commandes/ModifyOrder/Paiements";
import { useTranslation } from "react-i18next";

const NewDepenseTabContent = ({ activeTab }) => {
  const { t } = useTranslation("common");
  // FormData for depense paiements
  // const [formData, setFormData] = useState({ products: [], payments: [] });
  const { user } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();

  const [attachments, setAttachments] = useState(attachmentsDepListData);
  const [forms, setForms] = useState({
    infos: {
      users_permissions_user: user?.id,
    },
  });

  const handleFormChange = (name, formData) =>
    setForms({ ...forms, [name]: formData });
  useEffect(() => {
    return () => dispatch({ type: "CLEAR_CURRENT_EXPENSES" });
  }, [dispatch]);

  switch (activeTab) {
    case "General information":
      return (
        <NewDepenseGeneralInfo
          initialValues={forms.infos}
          attachments={attachments}
          setAttachments={setAttachments}
          handleFormChange={handleFormChange}
        />
      );
    // case "Paiements":
    //   return <Paiements formData={formData} setFormData={setFormData} />;
    case "Comment":
      return <NewDepenseCommentaire />;

    default:
      <NewDepenseGeneralInfo />;
  }
};

export default NewDepenseTabContent;
