import React, { useEffect, useState } from "react";

import adjustPrice from "../../../Utils/AdjustPrice";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";
import { getProductType } from "../../../Utils";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

function OrderProductItem({ packing, credit }) {
  const { t, i18n } = useTranslation("common");
  const { language } = i18n;
  const { user } = useSelector((state) => state.userReducer);
  const [packingStock, setpackingStock] = useState();

  useEffect(() => {
    moment(
      packing?.approvisionnement?.arrivalDate ||
        packing?.approvisionnement?.estimatedDate
    )?.diff(moment(), "day") < 0
      ? setpackingStock(t("In stock"))
      : packing?.approvisionnement?.arrivalDate ||
        packing?.approvisionnement?.estimatedDate
      ? setpackingStock(
          `${t("In supply on")} ${moment(
            packing?.approvisionnement?.arrivalDate ||
              packing?.approvisionnement?.estimatedDate
          )?.format("D/M/YYYY")}`
        )
      : setpackingStock();
  }, [
    packing?.approvisionnement?.arrivalDate,
    packing?.approvisionnement?.estimatedDate,
    t,
  ]);
  console.log(
    packing?.approvisionnement?.arrivalDate,
    packing?.approvisionnement?.estimatedDate
  );
  console.log({ packingStock });

  return (
    <>
      <div className="row gx-0 px-5 py-3 alignH">
        <div className={`col-3 px-0 alignH`}>
          <img
            src={`${IMAGE_END_POINT}${packing.mainImageURL}`}
            alt="product"
            style={{ width: "90px" }}
            className="br10 col-1 me-2"
          />
          <div className={`normal px-0 f14 text-brightGrey me-3`}>
            <p className="my-0">
              {language === "en"
                ? (packing.productName_en ?? packing.productName) ||
                  packing?.name
                : (packing.productName || packing?.name) ?? "-"}
            </p>

            <div className="ms-auto pe-5">
              {packing.cuve || packing.panel ? (
                <div className="f12 px-0 text-brightGrey">
                  {t(`${packing.cuve}`)} / {t(`${packing.panel}`)}
                </div>
              ) : null}
              {user?.attributes?.userRole === 1 &&
                getProductType(packing?.productType, t) !== "Services" && (
                  <div className="text-blue f12 bold">
                    Container: {packing?.approvisionnement?.containerName}
                  </div>
                )}
            </div>

            {getProductType(packing?.productType, t) !== "Services" &&
              packing?.numberOfProducts !== 0 &&
              packingStock && (
                <div className="f12 bold w100 d-flex flex-column justify-content-between">
                  <div
                    className={
                      packingStock === t("In stock")
                        ? `text-green`
                        : "text-brown"
                    }
                  >
                    {packingStock}
                  </div>
                </div>
              )}
          </div>
        </div>
        <span className={`col-1 normal f14 px-0 text-brightGrey `}>
          {packing.reference ?? "-"}
        </span>
        <span className={`col-2 normal f14 px-0 text-brightGrey `}>
          {packing.refClient || "-"}
        </span>
        <span className={`col-2 normal px-0 f14 text-brightGrey`}>
          {packing.serialNumber || "-"}
        </span>
        <span className={`col-1 normal f14 px-0 text-brightGrey `}>
          {packing.numberOfProducts}
        </span>
        <span className={` normal col-1 px-0 f14 text-brightGrey textNoWrap`}>
          {packing.discount
            ? adjustPrice(
                (
                  (credit ? -packing.discount : packing.discount) *
                  packing?.numberOfProducts
                )
                  ?.toFixed(2)
                  ?.toString() || 0
              ) + " €"
            : "-"}{" "}
        </span>
        <div className={`my-0 col-1 d-flex flex-column`}>
          {packing.discount !== null && packing.discount !== 0 && (
            <span
              className={`text-decoration-line-through normal f14 px-0 text-brightGrey textNoWrap`}
            >
              {adjustPrice(
                (credit ? -packing.unitPrice : packing.unitPrice)
                  ?.toFixed(2)
                  ?.toString() || 0
              )}{" "}
              €
            </span>
          )}
          <span className={`col-1 normal f14 px-0 text-brightGrey textNoWrap`}>
            {adjustPrice(
              (packing?.numberOfProducts > 1 && packing?.productType !== 1
                ? credit
                  ? -(packing.unitPrice - packing?.discount)
                  : packing.unitPrice - packing?.discount
                : credit
                ? -packing.unitPriceAfterDiscount
                : packing.unitPriceAfterDiscount
              )
                ?.toFixed(2)
                ?.toString() || 0
            )}{" "}
            €
          </span>
        </div>

        <span className={`col-1 normal f14 px-0 text-brightGrey textNoWrap`}>
          {adjustPrice(
            (packing?.numberOfProducts > 1 && packing?.productType !== 1
              ? (credit
                  ? -(packing.unitPrice - packing?.discount)
                  : packing.unitPrice - packing?.discount) *
                packing.numberOfProducts
              : (credit
                  ? -packing.unitPriceAfterDiscount
                  : packing.unitPriceAfterDiscount) * packing.numberOfProducts
            )
              .toFixed(2)
              ?.toString() || 0
          )}{" "}
          €
        </span>
      </div>
      <hr
        style={{
          border: `1px solid white`,
          opacity: 0.8,
          width: "97%",
          margin: "0.5rem auto ",
        }}
      />
    </>
  );
}

export default OrderProductItem;
