import React, { useEffect, useState } from "react";
import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import Informations from "./Informations";
import Button from "../../Common/FormElements/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCurrentProfile,
  createUpdateProfile,
  getProfile,
} from "../../../REDUX/actions/profile";
import { emailExists } from "../../LoginForm/emailValidation";
import { closeRightModal } from "../../../REDUX/actions/rightModal";
import { profileForm } from "./data";
import { useTranslation } from "react-i18next";
import API from "../../../Api/Api";
import { API_END_POINT } from "../../../Api/EndPoints";

function NewIntermediary({ id, resellerId }) {
  const { t } = useTranslation("common");

  const dispatch = useDispatch();
  const { profile, createLoading: loading } = useSelector(
    (state) => state.profileReducer,
  );
  const [activeTab, setActiveTab] = useState("Informations");
  const [formData, setFormData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [customErrors, setCustomErrors] = useState({});

  const handleClick = async () => {
    try {
      const currEmail = profile?.attributes?.user?.data?.attributes?.email;
      if (!id || currEmail !== formData?.email) {
        const exists = await emailExists(formData?.email);
        if (exists) {
          setCustomErrors({ ...customErrors, email: "exist" });
          return;
        }
      }
      const phoneNumberPattern = /^(\+|\d{1,4})\s?(\d\s?){5,}$/;
      if (!formData.phoneNumber?.match(phoneNumberPattern)) {
        setCustomErrors({
          ...customErrors,
          phoneNumber: "phoneNumberError",
        });
        return;
      }
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!formData?.email.match(emailPattern)) {
        setCustomErrors({
          ...customErrors,
          email: "emailError",
        });
        return;
      }

      dispatch(
        createUpdateProfile(
          formData,
          resellerId,
          profile?.attributes?.user?.data?.id,
          profile?.id,
          () => dispatch(closeRightModal()),
        ),
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(getProfile(id));
  }, [id, dispatch]);

  useEffect(() => {
    return () => dispatch(clearCurrentProfile());
  }, [dispatch]);

  useEffect(() => {
    setFormData((currState) => {
      const updatedFormData = { ...currState };

      profileForm.forEach(({ name, type }) => {
        if (type === "select") {
          updatedFormData[name] = {
            name: profile?.attributes?.user?.data?.attributes[name],
            code: profile?.attributes?.user?.data?.attributes[name],
          };
        } else {
          updatedFormData[name] =
            profile?.attributes?.user?.data?.attributes[name];
        }
      });
      return updatedFormData;
    });
  }, [profile]);

  useEffect(() => {
    (async function () {
      try {
        const { data } = await API.get(
          `${API_END_POINT}api/organizations/${resellerId}`,
          {
            params: {
              fields: ["id"],
              populate: {
                marque: {
                  fields: ["id"],
                },
              },
            },
          },
        );
        setFormData((prev) => ({
          ...prev,
          marque: data?.data?.attributes?.marque?.data?.id,
        }));
      } catch {}
    })();
  }, [resellerId]);

  return (
    <div className="newIntermediary d-flex flex-column justify-content-between h100">
      <div>
        <h2 className="sBold f25 quickSand text-blue px-5 mb-5">
          {id ? t("Edit profile") : t("Add a new profile")}
        </h2>
        <TabsNavbar
          tabs={["Informations"]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          className="gap-5"
        />
        <Informations
          isUpdating={!!profile}
          formData={formData}
          setFormData={setFormData}
          setDisabled={setDisabled}
          customErrors={customErrors}
          setCustomErrors={setCustomErrors}
        />
      </div>
      <div className="buttonsContainer d-flex justify-content-center mt-5">
        <Button variant="outline" className="me-4">
          {t("Cancel")}
        </Button>
        <Button
          className="me-4"
          loading={loading}
          disabled={disabled}
          onClick={loading ? undefined : handleClick}
        >
          {t("Save")}
        </Button>
        {/* <Button variant="lightBlue">Envoyer les accés</Button> */}
      </div>
    </div>
  );
}

export default NewIntermediary;
