import React, { useState } from "react";
import ProductTableHeader from "./ProductTableHeader";
import ProductTableBody from "./ProductTableBody";
import ProductTableFooter from "./ProductTableFooter";

function ProductTable({ productDetails }) {
  const category = productDetails?.category?.data?.attributes?.name;
  const type = productDetails?.productType;
  const [activeTab, setActiveTab] = useState(
    type === 2 ? "Associated products" : "Features",
  );
  const TABS = {
    SPA: ["Features", "Equipments", "LightSensor Serie", "Accessories"],
    "SPA DE NAGE": [
      "Features",
      "Equipments",
      "LightSensor Serie",
      "Accessories",
    ],
    SAUNA: ["Features", "Equipments", "Accessories"],
    ACCESSORY: ["Associated products", "Features"],
  };

  const currentCategory = type === 2 ? "ACCESSORY" : category;

  console.log(productDetails);
  /* ******************************** RENDERING ******************************* */
  return (
    <div className="productTable d-flex flex-column flex-grow-1">
      <ProductTableHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={TABS[currentCategory]}
      />
      <ProductTableBody
        category={category}
        productDetails={productDetails}
        activeTab={activeTab}
      />
      {/* <ProductTableFooter productDetails={productDetails} /> */}
    </div>
  );
}

export default ProductTable;
