import LoaderWrapper from "../../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import defaultImage from "../../../Images/Images/default-image.webp";
import { useTranslation } from "react-i18next";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";
import { Link } from "react-router-dom";
import { GET_PRODUCTS_ACCESSORIES } from "../../../REDUX/Graphql/Queries/products";
import { useEffect, useState } from "react";
import client from "../../../REDUX/actions/Client";

const ProductAccessories = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    (async function getProducts() {
      try {
        setLoading(true);
        const { data } = await client.query({
          query: GET_PRODUCTS_ACCESSORIES,
          variables: {
            id,
          },
        });
        setData(data?.products?.data);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <LoaderWrapper loading={loading}>
      <div
        className="overflow-auto d-flex flex-column py-4"
        style={{
          maxHeight: "500px",
        }}
      >
        {data?.map((accessory) => {
          const image = accessory?.attributes?.attachments?.data?.find(
            (item) => {
              return item?.attributes?.file?.data?.attributes?.mime.includes(
                "image",
              );
            },
          )?.attributes?.file?.data?.attributes?.url;
          return (
            <Link
              key={accessory.id}
              className="d-flex gap-4 justify-content-between align-items-center pointer text-ultraBlack p-1"
              to={`/catalogue/produit/${accessory.id}`}
            >
              <span className="f16 sBold quickSand ultraBlack w100">
                {i18n?.language?.includes("en")
                  ? accessory?.attributes?.name_en
                  : accessory?.attributes?.name}
              </span>

              <div className="f14 normal overflow-hidden">
                <img
                  src={image ? `${IMAGE_END_POINT}${image}` : defaultImage}
                  alt="product"
                  className={`size-50px ${image ? "object-contain" : "object-contain scale-3-50"}`}
                />
              </div>
            </Link>
          );
        })}
      </div>
    </LoaderWrapper>
  );
};

export default ProductAccessories;
