import React, { useState } from "react";
import ProductCarousel from "./ProductCarousel";
import ProductTable from "./ProductTable";
import Cart from "../../NewComponents/Cart";
import { useTranslation } from "react-i18next";

function ProductSummary({ productDetails }) {
  const { i18n } = useTranslation();
  const [step, setStep] = useState(1);
  return (
    <>
      <h1 className="f28 mb-4">
        {i18n.language?.includes("en")
          ? productDetails.name_en
          : productDetails.name}
      </h1>
      <div
        className="d-flex gap-3"
        style={{
          paddingRight: "calc(35vw - 130px)",
        }}
      >
        <ProductCarousel photos={productDetails.photos} />
        <ProductTable productDetails={productDetails} />
        <Cart
          hideCTA
          step={step}
          setStep={setStep}
          // activeTab={activeTab}
          productsactiveTab={productDetails?.attributes?.category?.data?.name}
          activeTab={productDetails?.attributes?.category?.data?.name}
        />
      </div>
      {productDetails?.description?.length > 0 && (
        <div
          className="w-100"
          style={{
            paddingRight: "calc(35vw - 130px)",
          }}
        >
          <p className="f20 sBold text-ultraBlack">Description</p>
          <p className="f16 text-lightBlack">
            {(i18n.language?.includes("en")
              ? productDetails?.description_en
              : productDetails?.description) ?? "-"}
          </p>
        </div>
      )}
    </>
  );
}

export default ProductSummary;
