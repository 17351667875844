import React from "react";
import Separator from "../../../Components/Common/Separator/Separator";
import getDetailsTableData from "./getDetailsTableData";
import { useTranslation } from "react-i18next";
import ProductAccessories from "./ProductAccessories";
import RelatedProducts from "./RelatedProducts";

function TableItem({ name, value }) {
  const displayValue =
    value !== undefined && value !== "[object Undefined]" ? value : "-";
  return (
    <div>
      <div className="d-flex p-3 justify-content-between">
        <p className="mb-0 f15 bold text-softBlack">{name} :</p>
        <p className="mb-0 f15 text-start text-softBlack text-capitalize">
          {displayValue}
        </p>
      </div>
      <Separator />
    </div>
  );
}
function ProductTableBody({ productDetails, activeTab }) {
  const { t } = useTranslation("common");
  const caracteristique = productDetails?.caracteristique?.data?.attributes;
  const extraFields = caracteristique?.data?.attributes?.extraFields;
  const type = productDetails?.productType;
  return (
    <div
      className={`${activeTab !== "Accessories" ? "p-4" : ""} flex-grow-1 overflow-hidden`}
    >
      {activeTab === "Accessories" && (
        <>
          <ProductAccessories id={productDetails.id} />
        </>
      )}
      {activeTab === "Associated products" && (
        <RelatedProducts id={productDetails.id} />
      )}
      {activeTab === "Features" && !extraFields?.length > 0 && type === 2 && (
        <p className="py-4">{t("This accessory has no characteristics")}.</p>
      )}
      {getDetailsTableData(productDetails, activeTab, t)?.map(
        ({ name, value, hide }, index) => {
          if (hide) return null;
          return <TableItem key={index} name={name} value={value} />;
        },
      )}
    </div>
  );
}

export default ProductTableBody;
