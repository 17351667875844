import React, { useEffect, useState } from "react";

import TabsNavbar from "../Common/TabsNavBar/TabsNavbar";
import "./NewProductAdmin.css";
import { getCategories } from "../../REDUX/actions/category";
import { useDispatch, useSelector } from "react-redux";
import { getModels } from "../../REDUX/actions/model";
import {
  clearCurrentProduct,
  getCurrentProduct,
  updateCurrentProduct,
} from "../../REDUX/actions/products";
import TabContentUpdateProduct from "./TabContentUpdateProduct";
import Button from "../Common/FormElements/Button/Button";
import {
  closeRightModal,
  openRightModal,
} from "../../REDUX/actions/rightModal";
import {
  caractProductForm,
  equipementForm,
  lightSensorForm,
  productForm,
} from "./NewProductTabContents/data";
import { convertedByType, getProductType } from "../../Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import UpdateTicketSav from "../Sav/UpdateTicketSavAdmin/UpdateTicketSav";
import { useLocation } from "react-router-dom";
import { getRanges } from "../../REDUX/actions/range";
import { getProductSizes } from "../../REDUX/actions/productSize";
import { getDeliveryTypes } from "../../REDUX/actions/deliveryTypes";
import { useTranslation } from "react-i18next";

const UpdateProductAdmin = ({ id, savID }) => {
  const { t, i18n } = useTranslation("common");
  const initialTabs = [
    "General information",
    "Translation",
    "Features",
    "Equipments",
    "LightSensor Serie",
    "Photos/Videos",
    "Documents",
  ];
  const productTypeMap = {
    1: "Products",
    2: "Accessories",
    3: "Marketing Products",
    4: "Spare Part",
    5: "Services",
    6: "Other branded products",
  };
  const dispatch = useDispatch();
  const {
    currentProduct,
    currentChars,
    currentProductLoading: loading,
    currentEquipement,
    currentDocuments,
    currentAttachements,
    currentLightSensor,
  } = useSelector((state) => state.productsReducer);

  const [tabs, setTabs] = useState(initialTabs);
  const [activeTab, setActiveTab] = useState("General information");
  const [disabled, setDisabled] = useState(false);
  const [forms, setForms] = useState({
    product: {},
    caracteristiques: {},
    equipement: {},
    lightSensor: {},
    media: [],
    documents: [],
  });

  // console.log({ currentProduct, currentDocuments, currentAttachements });

  const location = useLocation();

  const handleClick = () => {
    dispatch(
      updateCurrentProduct(forms, id, () => dispatch(closeRightModal()), t)
    );
  };

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getRanges(i18n.language));
    dispatch(getProductSizes());
    dispatch(getDeliveryTypes());
  }, [dispatch, i18n.language]);

  useEffect(() => {
    dispatch(getCurrentProduct(id));
    return () => dispatch(clearCurrentProduct());
  }, [dispatch, id]);

  useEffect(() => {
    setForms((currState) => {
      const updatedFormData = { ...currState };

      if (currentChars?.id !== null && currentChars?.id !== undefined) {
        caractProductForm?.forEach(({ name }) => {
          updatedFormData.caracteristiques[name] =
            currentChars?.attributes[name];
        });
        updatedFormData.caracteristiques.extrafields =
          currentChars.attributes.extraFields;
      }
      productForm?.forEach(({ name, type }) => {
        if (type === "multiSelectCheckbox") {
          updatedFormData.product[name] = currentProduct?.attributes[
            name
          ]?.data?.map((el) => +el?.id);
        } else if (type === "select") {
          if (name === "productType") {
            updatedFormData.product.productType = {
              name: productTypeMap[currentProduct?.attributes?.productType],
              code: currentProduct?.attributes?.productType,
            };
          } else {
            if (name === "kangourou") {
              if (currentProduct?.attributes?.kangourou) {
                updatedFormData.product.kangourou = {
                  code: 1,
                  name: t("With kangaroo pocket"),
                };
              } else {
                updatedFormData.product.kangourou = {
                  code: 0,
                  name: t("Without kangaroo pocket"),
                };
              }
            } else {
              const code = currentProduct?.attributes[name]?.data?.id;
              updatedFormData.product[name] = {
                name: currentProduct?.attributes[name]?.data?.attributes?.name,
                code: convertedByType(code),
              };
            }
          }
        } else {
          updatedFormData.product[name] = currentProduct?.attributes[name];
        }
      });

      equipementForm?.forEach(({ name, type }) => {
        if (type === "select") {
          const code = currentEquipement?.attributes[name];
          updatedFormData.equipement[name] = {
            name: code ? t("Yes", { ns: "common" }) : t("No", { ns: "common" }),
            code: code,
          };
        } else {
          updatedFormData.equipement[name] =
            currentEquipement?.attributes[name];
        }
      });

      for (const i in currentDocuments) {
        const createdAttachement = currentDocuments[i];
        updatedFormData.documents[i] = {
          ...updatedFormData.documents[i],
          icon: {
            name: convertedByType(
              createdAttachement?.attributes?.icon ??
                updatedFormData?.documents[i]?.icon?.name
            ),
            code: convertedByType(
              createdAttachement?.attributes?.icon ??
                updatedFormData?.documents[i]?.icon?.code
            ),
          },
          docType: {
            name: convertedByType(
              createdAttachement?.attributes?.type ??
                updatedFormData?.documents[i]?.docType?.name
            ),
            code: convertedByType(
              createdAttachement?.attributes?.type ??
                updatedFormData?.documents[i]?.docType?.code
            ),
          },
          id: convertedByType(
            createdAttachement?.id ?? updatedFormData?.documents[i]?.id
          ),
          title: createdAttachement?.attributes?.title,
          fileId: createdAttachement?.attributes?.file?.data?.id,
          fileUrl: createdAttachement?.attributes?.file?.data?.attributes?.url,
          attachmentId: createdAttachement?.id,
          fileName:
            createdAttachement?.attributes?.file?.data?.attributes?.name,
          file: createdAttachement?.attributes?.file,
        };
      }

      for (const i in currentAttachements) {
        const createdAttachement = currentAttachements[i];
        updatedFormData.media[i] = {
          ...updatedFormData.media[i],
          id: createdAttachement?.id ?? updatedFormData.media[i]?.id,
          title: createdAttachement?.attributes?.title,
          fileId: createdAttachement?.attributes?.file?.data?.id,
          fileUrl: createdAttachement?.attributes?.file?.data?.attributes?.url,
          file: createdAttachement?.attributes?.file,
          fileName:
            createdAttachement?.attributes?.file?.data?.attributes?.name,
          attachmentId: createdAttachement?.id,
          mediaType:
            createdAttachement?.attributes?.file?.data?.attributes?.mime?.includes(
              "image"
            )
              ? "image"
              : "video",
          date: createdAttachement?.attributes?.date,
        };
      }

      lightSensorForm?.forEach(({ name, type }) => {
        if (type === "switch") {
          updatedFormData.lightSensor[name] =
            currentLightSensor?.attributes[name];
        } else {
          updatedFormData.lightSensor[name] =
            currentLightSensor?.attributes[name];
        }
      });
      return updatedFormData;
    });
  }, [
    currentAttachements,
    currentChars,
    currentDocuments,
    currentEquipement?.attributes,
    currentProduct?.attributes,
    currentLightSensor?.attributes,
  ]);
  useEffect(() => {
    if (forms?.product?.productType?.code !== 1) {
      getProductType(forms.product.productType?.code, t) === t("Services")
        ? setTabs((prevTabs) =>
            prevTabs.filter(
              (tab) =>
                tab !== "Equipments" &&
                tab !== "LightSensor Serie" &&
                tab !== "Features"
            )
          )
        : setTabs((prevTabs) =>
            prevTabs.filter(
              (tab) => tab !== "Equipments" && tab !== t("LightSensor Serie")
            )
          );
    } else {
      setTabs(initialTabs);
    }
    return () => setTabs(initialTabs);
  }, [forms?.product?.productType?.code]);

  return (
    <div className="d-flex flex-column justify-content-between h100">
      <div className="alignH align-items-end">
        {location.pathname === "/sav" && (
          <FontAwesomeIcon
            icon={faArrowLeftLong}
            size={"xl"}
            className="ps-5 mb-5 pointer text-blue"
            onClick={() =>
              dispatch(openRightModal(<UpdateTicketSav id={savID} />))
            }
          />
        )}
        <span
          className={`sBold f25 quickSand text-blue mb-5 ${
            location.pathname === "/sav" ? "px-3" : "px-5"
          }`}
        >
          {t("Modify the product")}
        </span>
      </div>
      <div className="mt-4 d-flex flex-column flex-1">
        <TabsNavbar
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          className="justify-content-between"
        />
        <div className="px-5 mt-4 d-flex flex-column h-100">
          <TabContentUpdateProduct
            activeTab={activeTab}
            setDisabled={setDisabled}
            forms={forms}
            setForms={setForms}
          />
          <div className="w-100 alignC gap-3 px-5 pt-3 mt-auto">
            <Button
              variant={"outline"}
              className="px-5"
              onClick={() => dispatch(closeRightModal())}
            >
              {t("Cancel", { ns: "common" })}
            </Button>
            <Button
              className="px-5"
              disabled={disabled}
              loading={loading}
              onClick={loading ? undefined : handleClick}
            >
              {t("Save", { ns: "common" })}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProductAdmin;
