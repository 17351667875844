import React from "react";
import { formatDate } from "../../../Utils/fileUtils";
import { useSelector } from "react-redux";
import adjustPrice from "../../../Utils/AdjustPrice";
import { useTranslation } from "react-i18next";

const CommandeDetails = ({ commande }) => {
  const { t } = useTranslation("common");
  const { userRole } = useSelector((state) => state.userReducer);

  const contentTab = [
    {
      label: t("Order Number"),
      value: commande?.number ? `#${commande?.number}` : "-",
    },
    {
      label: t("Order Date"),
      value:
        (commande?.oldCreatedAt
          ? formatDate(commande?.oldCreatedAt, "DD-MM-YYYY")
          : formatDate(commande?.createdAt, "DD-MM-YYYY")) || "-",
    },
    {
      label: t(commande?.credit ? "Credit No" : "Invoice No"),
      value: commande?.facture?.number
        ? `#${commande?.facture?.number}`
        : commande?.avoir?.number
        ? `#${commande?.avoir?.number}`
        : "-",
    },
    {
      label: t(commande?.credit ? "Credit Date" : "Invoice Date"),
      value:
        (commande?.credit ? commande?.avoir?.date : commande?.facture?.date) ||
        "-",
    },
    {
      label: t("Purchase Order No"),
      value: commande?.bon_de_commande?.number
        ? `#${commande?.bon_de_commande?.number}`
        : "-",
    },
    {
      label: t("Purchase Order Date"),
      value: formatDate(commande?.bon_de_commande?.date, "DD-MM-YYYY") || "-",
    },
    {
      label: t("Client"),
      value: commande?.reseller?.name || commande?.particulierName || "-",
    },
    {
      label:
        userRole === "BusinessBroker"
          ? t("Commission")
          : t("Business affiliate"),
      value:
        userRole === "BusinessBroker"
          ? commande?.comission
            ? `${commande?.comission} €`
            : "-"
          : commande?.business_affiliate?.user?.firstName ||
            commande?.business_affiliate?.user?.lastName
          ? `${commande?.business_affiliate?.user?.firstName} ${commande?.business_affiliate?.user?.lastName}`
          : "-",
    },
    userRole === "admin" && {
      label: `${t("Commission")} (${
        commande?.particulier ? t("Including Taxes") : t("Excluding Taxes")
      })`,
      value: commande?.comission ? `${commande?.comission?.toFixed(2)} €` : "-",
    },
    {
      label: t("Created by"),
      value: commande?.creator?.firstName || "-",
    },

    {
      label: t("Status"),
      value: commande?.status ? t(`${commande?.status}`) : "-",
    },
    {
      label: t("Payment"),
      value: commande?.payStatus ? t(`${commande?.payStatus}`) : "-",
    },
    {
      label: t("Amount Excl. Tax"),
      value: commande?.totalHT
        ? `${adjustPrice(commande?.totalHT.toFixed(2).toString())} €`
        : "-",
    },
    {
      label: t("Amount Incl. Tax"),
      value: commande?.totalTTC
        ? `${adjustPrice(commande?.totalTTC.toFixed(2).toString())} €`
        : "-",
    },
    {
      label: t("VAT"),
      value: commande?.TVA
        ? `${adjustPrice(commande?.TVA.toFixed(2).toString())} €`
        : "-",
    },
    userRole === "admin" && {
      label: t("Margin"),
      value: commande?.marge
        ? `${adjustPrice(commande?.marge.toFixed(2).toString())} €`
        : "-",
    },
    userRole === "admin" && {
      label: t("Margin Rate"),
      value: commande?.txMarge ? `${commande?.txMarge?.toFixed(2)} %` : "-",
    },
    {
      label: t("Delivery Fees Excl. Tax"),
      value: commande?.totalDeliveryHT
        ? `${adjustPrice(commande?.totalDeliveryHT.toFixed(2).toString())} €`
        : "-",
    },
    {
      label: t("Delivery Fees Including Tax"),
      value: commande?.totalDeliveryTTC
        ? `${adjustPrice(commande?.totalDeliveryTTC.toFixed(2).toString())} €`
        : "-",
    },
    {
      label: t("Delivery Method"),

      value: commande?.delivery_type?.name
        ? commande?.delivery_type?.name
        : "-",
    },
    {
      label: t("Estimated Delivery Date"),
      value: commande?.estimatedDeliveryDate || "-",
    },
    {
      label: t("Actual Delivery Date"),
      value: commande?.realDeliveryDate || "-",
    },
    {
      label: t("Delivery Address"),
      value: commande?.addresseDeLivraison
        ? `${commande?.addresseDeLivraison?.street}, </br>${commande?.addresseDeLivraison?.zip} ${commande?.addresseDeLivraison?.city?.name},</br> ${commande?.addresseDeLivraison?.country?.name}`
        : "-",
    },
    {
      label: t("Billing Address"),
      value: commande?.addresseDeFacturation
        ? `${commande?.addresseDeFacturation?.street}, </br>${commande?.addresseDeFacturation?.zip} ${commande?.addresseDeFacturation?.city?.name}, </br>${commande?.addresseDeFacturation?.country?.name}`
        : "-",
    },
    {
      label: t("Customer Service Order"),
      value: commande?.free ? t("Yes") : t("No"),
    },
    {
      label: t("Individual Order"),
      value: commande?.particulier ? t("Yes") : t("No"),
    },
    commande?.particulier && {
      label: t("Individual Name"),
      value: commande?.particulierName || "-",
    },
    commande?.particulier && {
      label: t("Individual Email"),
      value: commande?.particulierEmail || "-",
    },
    commande?.particulier && {
      label: t("Individual Company"),
      value: commande?.particulierCompany || "-",
    },
    commande?.particulier && {
      label: t("Individual Phone No"),
      value: commande?.particulierPhone || "-",
    },

    {
      label: t("Major account order"),
      value: commande?.majorAccountOrder ? t("Yes") : t("No"),
    },

    commande?.majorAccountOrder && {
      label: t("Major account Name"),
      value: commande?.majorAccountName || "-",
    },
    commande?.majorAccountOrder && {
      label: t("Major account Email"),
      value: commande?.majorAccountEmail || "-",
    },

    commande?.majorAccountOrder && {
      label: t("Major account Phone No"),
      value: commande?.majorAccountPhone || "-",
    },
  ];

  return (
    <div className="d-flex  my-3  align-items-start flex-wrap">
      {contentTab
        ?.filter((item) => item)
        ?.map((item, index) => {
          return (
            <div className="my-3 col-3" key={index}>
              <p className="f14 bold text-ultraBlack mb-2">{item?.label}</p>
              <p
                className={`f14 text-ultraBlack mb-2`}
                dangerouslySetInnerHTML={{ __html: item?.value }}
              ></p>
            </div>
          );
        })}
    </div>
  );
};

export default CommandeDetails;
