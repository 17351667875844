import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ArrowIcon from "../../icons/ArrowIcon";
import "./CalendarInput.css";
import { useTranslation } from "react-i18next";

function CalendarInput({
  placeholder,
  width = "",
  className = "",
  customInputStyle,
  calendarIconSize,
  withMinDate = true,
  name,
  callback,
  value,
  view = "date",
  timeOnly = false,
  minDate = true,
  dateFormat = "dd/mm/yy",
  disabled,
}) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [date, setDate] = useState(undefined);

  const handleChange = (e) => {
    setDate(e.value);
    callback && callback(e);
  };

  const minDateProp = withMinDate ? moment().toDate() : undefined;

  if (!currentLanguage) return null;

  return (
    <Calendar
      value={value || date}
      onChange={handleChange}
      onSelect={(e) => setDate(e.value)}
      showIcon
      placeholder={placeholder}
      style={{
        width: width,
        "--calendarIconSize": calendarIconSize || "20px",
      }}
      className={`calendarInput ${className}`}
      icon={
        timeOnly ? (
          <ArrowIcon />
        ) : (
          <i className="bi bi-calendar3 calendarInputIcon" />
        )
      }
      dateFormat={dateFormat}
      appendTo="self"
      locale={currentLanguage?.includes("en") ? "en" : "fr"}
      minDate={minDate ? minDateProp : undefined}
      inputStyle={customInputStyle || { fontSize: "16px" }}
      name={name}
      view={view}
      timeOnly={timeOnly}
      disabled={disabled}
    />
  );
}

export default CalendarInput;
