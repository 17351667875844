import moment from "moment";

export const days = {
  fr: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
  en: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
};

export const months = {
  fr: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  en: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
};

export const shortDays = {
  fr: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  en: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
};

export const shortMonths = {
  fr: [
    "Janv",
    "Févr",
    "Mars",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Août",
    "Sept",
    "Oct",
    "Nov",
    "Déc",
  ],
  en: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
};

// export const getPointColor = (day) => {
//   switch (day) {
//     case 1:
//     case 18:
//       return "var(--bs-wildStrawberry)";
//     case 2:
//       return "var(--bs-aquaGreen)";
//     case 4:
//     case 22:
//       return "var(--bs-yellowOrange)";
//     case 10:
//       return "var(--bs-jasminePurple)";
//     case 13:
//       return "var(--bs-yellowGreen)";
//     case 16:
//       return "#3ABAEA";
//     default:
//       return "";
//   }
// };
export const getPointColor = (day, dates) => {
  const formattedDate = moment(day).format("DD/MM/YY");
  const matchingDate = dates.find((date) => date.startDate === formattedDate);

  if (matchingDate) {
    switch (matchingDate.key) {
      case "event":
        return "var(--bs-wildStrawberry)";
      case "order":
        return "var(--bs-aquaGreen)";
      // Add more cases as needed
      default:
        return "";
    }
  } else {
    return "";
  }
};
const colorPicker = {
  event: "",
};
