import qs from "qs";
import API from "../../Api/Api";
import { API_END_POINT } from "../../Api/EndPoints";
import { isDefined, selectValue, uploadFile } from "../../Utils";
import { deleteRecord } from "../../Utils/api";
import moment from "moment";
import _ from "lodash";
import { toast } from "react-toastify";

export const getExpenses = (filters) => async (dispatch, getState) => {
  try {
    dispatch({ type: "FETCH_EXPENSES_LOADING" });

    const { user } = getState().userReducer;
    let dateMonth;
    if (filters?.month) {
      dateMonth = moment(
        `${filters?.year || moment().year()}-${filters?.month}-01`
      );
    }

    let dateYear;
    if (filters?.year) {
      dateYear = moment(`${filters?.year}-01-01`);
    }

    const query = qs.stringify({
      populate: ["attachment.file", "comments"],
      sort: ["createdAt:desc"],
      filters: {
        date: dateMonth
          ? {
              $gte: moment(dateMonth).startOf("month").format("DD-MM-YYYY"),
              $lte: moment(dateMonth).endOf("month").format("DD-MM-YYYY"),
            }
          : dateYear
          ? {
              $gte: moment(dateYear).startOf("year").format("DD-MM-YYYY"),
              $lte: moment(dateYear).endOf("year").format("DD-MM-YYYY"),
            }
          : {},
        type: { $containsi: filters?.type ?? "" },
        title: { $containsi: filters?.search ?? "" },
        vendor: { $containsi: selectValue(filters?.vendor) ?? "" },
        $or: [
          { payment_1: { $containsi: filters?.payment } },
          { payment_2: { $containsi: filters?.payment } },
          { payment_3: { $containsi: filters?.payment } },
        ],
      },
    });
    const queryMarque = `&filters[marque][id][$eq]=${user?.attributes?.marque?.data?.id}`;
    const {
      data: { data: expensesData },
    } = await API.get(`${API_END_POINT}api/expenses?${query}${queryMarque}`);
    dispatch({ type: "FETCH_EXPENSES_SUCCESS", payload: expensesData });
  } catch (error) {
    console.log(error);
    dispatch({ type: "FETCH_EXPENSES_ERROR", payload: error });
  }
};

export const getCurrentExpensesItem = (id) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_CURRENT_EXPENSES_LOADING" });
    const query = qs.stringify({
      populate: ["attachment.file"],
    });
    const {
      data: { data: expensesItemData },
    } = await API.get(`${API_END_POINT}api/expenses/${id}?${query}`);
    dispatch({
      type: "FETCH_CURRENT_EXPENSES_SUCCESS",
      payload: expensesItemData,
    });
  } catch (error) {
    dispatch({ type: "FETCH_CURRENT_EXPENSES_ERROR", payload: error });
  }
};

export const createUpdateExpenses =
  (formData, id, attachment, callback = () => {}, t) =>
  async (dispatch) => {
    try {
      dispatch({ type: "CREATE_UPDATE_EXPENSES_LOADING" });

      const {
        data: { data: currentExpenses },
      } = await API[isDefined(id) ? "put" : "post"](
        `${API_END_POINT}api/expenses/${id ?? ""}?populate=*`,
        {
          data: formData,
        }
      );

      let attachementData;
      if (
        attachment?.fileId !== null &&
        attachment?.fileId !== undefined &&
        attachment?.attachmentId !== null &&
        attachment?.attachmentId !== undefined
      ) {
        const { data } = await API.put(
          `${API_END_POINT}api/attachments/${attachment?.attachmentId}?populate=*`,
          {
            data: {
              title: attachment?.title,
            },
          }
        );
        attachementData = data;
      } else {
        const fileId = await uploadFile(attachment?.file);
        const { data } = await API.post(
          `${API_END_POINT}api/attachments?populate=*`,
          {
            data: {
              title: attachment?.title,
              date: new Date(attachment?.date),
              file: fileId,
              expense: currentExpenses?.id,
            },
          }
        );
        attachementData = data;
      }
      if (id) {
        dispatch({
          type: "UPDATE_EXPENSES_SUCCESS",
          payload: {
            ...currentExpenses,
            attributes: {
              ...currentExpenses?.attributes,
              attachment: attachementData,
            },
          },
        });
        toast.success(t("The expense has been successfully updated!"));
      } else {
        dispatch({
          type: "CREATE_EXPENSES_SUCCESS",
          payload: {
            ...currentExpenses,
            attributes: {
              ...currentExpenses?.attributes,
              attachment: attachementData,
            },
          },
        });
        toast.success(t("Expense successfully created!"));
      }
      callback();
    } catch (error) {
      if (isDefined(id)) {
        toast.error(t("An error occurred while updating the expense"));
      } else {
        toast.error(t("An error occurred while creating the expense"));
      }
      dispatch({ type: "CREATE_UPDATE_EXPENSES_ERROR" });
    }
  };

export const createComment =
  (comment, expense, user, successCallback, t) => async (dispatch) => {
    dispatch({ type: "CREATE_COMMENTS_LOADING" });

    try {
      const {
        data: { data: commentData },
      } = await API.post(`${API_END_POINT}api/comments?populate=*`, {
        data: {
          text: comment?.text,
          expense,
          user,
        },
      });

      if (comment?.attachments?.length > 0) {
        for (const file of comment?.attachments) {
          const fileId = await uploadFile(file);
          const { data } = await API.post(
            `${API_END_POINT}api/attachments?populate=*`,
            {
              data: {
                date: new Date(),
                file: fileId,
                comment: commentData?.id,
              },
            }
          );
          commentData.attributes.attachments.data?.push(data?.data);
        }
      }

      dispatch({
        type: "CREATE_COMMENTS_SUCCESS",
        payload: {
          ...commentData,
          attributes: { ...commentData?.attributes },
        },
      });
      successCallback();
      toast.success(t("A comment has been added"));
    } catch (error) {
      console.log(error);
    }
  };

export const getComments = (expense) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_COMMENTS_LOADING" });
    const query = qs.stringify({
      populate: [
        "attachments.file",
        "user.photo",
        "expense",
        "user.profile.reseller",
        "user.business_affiliate",
        "business_affiliate",
      ],
      sort: ["createdAt:desc"],
      filters: {
        expense: { id: { $eq: expense } },
      },
    });
    const {
      data: { data: commentData },
    } = await API.get(`${API_END_POINT}api/comments?${query}`);
    dispatch({ type: "FETCH_COMMENTS_SUCCESS", payload: commentData });
  } catch (error) {
    dispatch({ type: "FETCH_COMMENTS_ERROR", payload: error });
  }
};

export const deleteExpensesItem =
  (id, attachmentId, fileId, comments, t) => async (dispatch) => {
    try {
      console.log({ comments });
      // return;
      dispatch({ type: "DELETE_EXPENSES_ITEM_LOADING" });
      await deleteRecord(id, "expenses");
      await deleteRecord(attachmentId, "attachments");
      // await deleteRecord(fileId, "upload/files");
      if (comments) {
        for (const commentId of comments) {
          await deleteRecord(commentId, "comments");
        }
      }
      dispatch({ type: "DELETE_EXPENSES_ITEM_SUCCESS", payload: id });
      toast.success(t("Expense successfully deleted!"));
    } catch (error) {
      console.log({ error });

      toast.error(
        "Une erreur s'est produite lors de la suppression de la dépense"
      );
      dispatch({ type: "DELETE_EXPENSES_ITEM_ERROR", payload: error });
    }
  };

export const deleteExpenses = (expenses, t) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_EXPENSES_LOADING" });
    for (const expense of expenses) {
      await deleteRecord(expense?.id, "expenses");
      await deleteRecord(expense?.attachmentId, "attachments");
      // await deleteRecord(expense?.fileId, "upload/files");
      for (const commentId of expense?.comments) {
        await deleteRecord(commentId, "comments");
      }
    }
    dispatch({ type: "DELETE_EXPENSES_SUCCESS", payload: expenses });
    toast.success(t("Expense successfully deleted!"));
  } catch (error) {
    toast.error(
      "Une erreur s'est produite lors de la suppression de la dépense"
    );
    dispatch({ type: "DELETE_EXPENSES_ERROR", payload: error });
  }
};

export const getExpenseVendor = () => async (dispatch) => {
  try {
    const {
      data: { data: vendorData },
    } = await API.get(`${API_END_POINT}api/expenses?fields[0]=vendor`);
    const uniqVendors = _.uniqBy(vendorData, (e) => e?.attributes?.vendor)?.map(
      (item) => ({
        name: item?.attributes?.vendor,
        code: item?.attributes?.vendor,
      })
    );
    dispatch({ type: "FETCH_EXPENSE_VENDORS", payload: uniqVendors });
  } catch (error) {
    console.log(error);
  }
};

export const duplicateExpense = (expenseId, t) => async (dispatch) => {
  try {
    const res = await API.post(
      `${API_END_POINT}api/duplicateExpense?expenseId=${expenseId}`
    );
    dispatch({
      type: "CREATE_EXPENSES_SUCCESS",
      payload: { ...res?.data?.data },
    });
    toast.success(t("Expense successfully created!"));
  } catch (error) {
    console.log(error);
  }
};
