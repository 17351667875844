import API from "../../Api/Api";
import qs from "qs";
import { API_END_POINT } from "../../Api/EndPoints";
import { getRandomNumber, isDefined } from "../../Utils";
import { deleteRecord } from "../../Utils/api";
import { toast } from "react-toastify";

export const getAffiliates = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "FETCH_AFFILIATES_LOADING" });

    const { user } = getState().userReducer;
    const query = qs.stringify({
      filters: {
        marque: { id: { $eq: user?.attributes?.marque?.data?.id } },
      },
    });
    const { data } = await API.get(
      `${API_END_POINT}api/business-affiliates?${query}&populate=*&sort[0]=createdAt:desc`
    );

    dispatch({ type: "FETCH_AFFILIATES_SUCCESS", payload: data?.data });
  } catch (error) {
    dispatch({ type: "FETCH_AFFILIATES_ERROR", payload: error });
  }
};

export const getCurrentAffiliate = (id) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_CURRENT_AFFILIATE_LOADING" });
    const query = qs.stringify({
      populate: [
        "city",
        "country",
        "user",
        "comissions.range",
        "organizations",
        "email",
      ],
    });
    const {
      data: { data: affiliateData },
    } = await API.get(`${API_END_POINT}api/business-affiliates/${id}?${query}`);
    dispatch({
      type: "FETCH_CURRENT_AFFILIATE_SUCCESS",
      payload: affiliateData,
    });
  } catch (error) {
    dispatch({ type: "FETCH_CURRENT_AFFILIATE_ERROR" });
  }
};

export const createAffiliateInformations =
  (
    { informations, address, revendeurs, comissions },
    userId,
    informationId,
    callback,
    errors,
    t
  ) =>
  async (dispatch, getState) => {
    if (Object.keys(errors) > 0) {
      return;
    }
    try {
      dispatch({ type: "CREATE_AFFILIATE_INFO_LOADING" });
      const {
        firstName,
        lastName,
        phoneNumber,
        email,
        password,
        occupation,
        company,
      } = informations;
      const { TVACode, siret } = informations;

      const extraUserFields = userId
        ? {}
        : {
            username: `${firstName}-${lastName}-${getRandomNumber(1000, 9999)}`,
            role: 0,
          };

      const { data: userData } = await API[isDefined(userId) ? "put" : "post"](
        `${API_END_POINT}api/users/${userId ?? ""}`,
        {
          firstName,
          lastName,
          phoneNumber,
          email,
          password,
          occupation: occupation?.code,
          userRole: 2,
          ...extraUserFields,
        }
      );

      const {
        data: { data: informationData },
      } = await API[isDefined(informationId) ? "put" : "post"](
        `${API_END_POINT}api/business-affiliates/${
          informationId ?? ""
        }?populate=*`,
        {
          data: {
            company,
            siret,
            TVACode,
            user: userData?.id,
          },
        }
      );

      dispatch({
        type: "CREATE_AFFILIATE_INFO_SUCCESS",
        payload: { userData, informationData },
      });
      await dispatch(createAffiliateAddress(address, informationData?.id));

      const { currentComissions } = getState().affiliateReducer;
      await dispatch(
        createAffiliateComissions(
          comissions,
          informationData?.id,
          currentComissions?.map((item) => item?.id)
        )
      );
      await dispatch(createAffiliateReseller(revendeurs, informationData?.id));
      callback();
      if (isDefined(userId) && isDefined(informationId)) {
        toast.success(t("Business affiliate has been successfully updated!"));
      } else {
        toast.success(t("Business affiliate successfully created!"));
      }
    } catch (error) {
      if (isDefined(userId) && isDefined(informationId)) {
        toast.error(
          t("An error occurred while updating the business affiliate")
        );
      } else {
        toast.error(
          t("An error occurred while creating the business affiliate")
        );
      }
      dispatch({ type: "CREATE_AFFILIATE_INFO_ERROR", payload: error });
    }
  };

export const createAffiliateAddress =
  (formData, affiliateId) => async (dispatch) => {
    try {
      dispatch({ type: "CREATE_AFFILIATE_ADDRESS_LOADING" });
      const {
        data: { data: addressData },
      } = await API.put(
        `${API_END_POINT}api/business-affiliates/${affiliateId}?populate=*`,
        {
          data: {
            ...formData,
            country: formData?.country?.code,
            city: formData?.city?.code,
          },
        }
      );

      dispatch({
        type: "CREATE_AFFILIATE_ADDRESS_SUCCESS",
        payload: addressData,
      });
    } catch (error) {
      dispatch({ type: "CREATE_AFFILIATE_ADDRESS_ERROR", payload: error });
    }
  };

export const createAffiliateReseller =
  (formData, affiliateId) => async (dispatch) => {
    try {
      dispatch({ type: "CREATE_AFFILIATE_RESELLER_LOADING" });
      const organizations = formData?.map((item) => item?.code);
      const { data } = await API.put(
        `${API_END_POINT}api/business-affiliates/${affiliateId}?populate=*`,
        {
          data: {
            organizations,
          },
        }
      );

      dispatch({
        type: "CREATE_AFFILIATE_RESELLER_SUCCESS",
        payload: data?.data?.attributes?.organizations?.data,
      });
    } catch (error) {
      dispatch({ type: "CREATE_AFFILIATE_RESELLER_ERROR", payload: error });
    }
  };

export const deleteAffiliateReseller =
  (id, currentResellers, affiliateId) => async (dispatch) => {
    try {
      let canDelete = false;

      const organizations = currentResellers?.filter((item) => {
        if (+item?.id === +id) {
          canDelete = true;
          return false;
        }
        return true;
      });

      if (!canDelete) return;
      dispatch({ type: "DELETE_AFFILIATE_RESELLER_LOADING" });

      const { data } = await API.put(
        `${API_END_POINT}api/business-affiliates/${affiliateId}?populate=*`,
        {
          data: {
            organizations,
          },
        }
      );

      dispatch({
        type: "DELETE_AFFILIATE_RESELLER_SUCCESS",
        payload: data?.data?.attributes?.organizations?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: "DELETE_AFFILIATE_RESELLER_ERROR", payload: error });
    }
  };

export const createAffiliateComissions =
  (formData, affiliateId, currentComissions) => async (dispatch) => {
    try {
      dispatch({ type: "CREATE_AFFILIATE_COMISSION_LOADING" });
      const comissions = [];
      for (const index in formData) {
        const exist = index < currentComissions?.length;
        const {
          data: { data: commissionData },
        } = await API[exist ? "put" : "post"](
          `${API_END_POINT}api/comissions/${
            currentComissions[index] ?? ""
          }?populate=*`,
          {
            data: {
              ...formData[index],
              range: formData[index]?.range?.code,
              business_affiliate: affiliateId,
            },
          }
        );
        comissions.push(commissionData);
      }

      dispatch({
        type: "CREATE_AFFILIATE_COMISSION_SUCCESS",
        payload: comissions ?? [],
      });
    } catch (error) {
      dispatch({ type: "CREATE_AFFILIATE_COMISSION_ERROR", payload: error });
    }
  };

export const deleteAffiliateComissions =
  (id, currentComissions) => async (dispatch) => {
    try {
      if (!currentComissions?.find((item) => +item?.id === +id)) {
        return;
      }
      dispatch({ type: "DELETE_AFFILIATE_COMISSION_LOADING" });
      await API.delete(`${API_END_POINT}api/comissions/${id}`);

      dispatch({
        type: "DELETE_AFFILIATE_COMISSION_SUCCESS",
        payload: id,
      });
    } catch (error) {
      dispatch({ type: "DELETE_AFFILIATE_COMISSION_ERROR", payload: error });
    }
  };

export const deleteAffiliate =
  (id, userId, comissions = [], t) =>
  async (dispatch) => {
    dispatch({ type: "DELETE_AFFILIATE_LOADING" });
    try {
      await deleteRecord(id, "business-affiliates");
      await deleteRecord(userId, "users");

      for (const comission of comissions) {
        await deleteRecord(comission, "comissions");
      }

      dispatch({ type: "DELETE_AFFILIATE_SUCCESS", payload: id });
      toast.success(t("Business affiliate successfully deleted!"));
    } catch (error) {
      toast.error(t("An error occurred while deleting the business affiliate"));
      dispatch({ type: "DELETE_AFFILIATE_LOADING", payload: error });
    }
  };
