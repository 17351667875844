const initialState = {
  locale: null,
};

const localeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_LOCALE":
      return { locale: payload };

    default:
      return state;
  }
};

export default localeReducer;
